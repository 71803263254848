import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { Container, Grid, Box, Button, Typography }  from '@material-ui/core';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { sendUserLogin, userClearFailure } from '../actions/userActions'
import { Alert } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

const ValueTextField = styled(TextField)({
  paddingTop: '0px',
  marginBottom: '18px',
  position: 'releative',
  fontSize: '14px',
  height: '34px',
  width: '100%',
  '& input': {
    color: '004057',
    background: '#fff',
    borderRadius: '4',
    border: 'none',
    fontSize: '14px',
    padding: '10px 10px',
    lineHeight: '1.42857143',
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    background: '#fff',
    paddingRight: '10px',
  },
});

const LoginButton = styled(Button)({
  marginTop: '10px',
  height: '40px',
  fontSize: '14px',
  padding: '0px',
  background: '#42596b',
  '&:hover': {
    color: '#fff',
    background: '#004057',
  },
  '&:disabled': {
  },
});

const Header = styled(Typography)({
  marginTop: '40px',
  marginBottom: '20px',
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '24px',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  letterSpacing: '1.1px',
  width: '100%',
});

const Label = styled(Typography)({
  paddingTop: '7px',
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '15px',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  letterSpacing: '1px',
  width: '100%',
});

const AlertBox = styled(Box)({
  border: 'none',
  padding: '0px',
  marginBottom: '20px',
});

export function Login() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.user.loggedIn);
  const error = useSelector(state => state.user.error);
  const loading = useSelector(state => state.user.loading);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(sendUserLogin({ username: username, password: password }));
  }

  function handleClickShowPassword(e) {
    setShowPassword(!showPassword);
  };

  function handleMouseDownPassword(e) {
    e.preventDefault();
  };

  function renderError() {
    if (error) {
      return (
        <Grid item xs={12}>
          <AlertBox>
            <Alert
              key="login_error"
              severity="error"
              onClose={() => dispatch(userClearFailure())}>
              <span><strong>{error.msg}!</strong></span>
            </Alert>
          </AlertBox>
        </Grid>
      );
    }
  }

  if (loggedIn) {
    return (<Redirect to="/operation" />);
  }

  return (
    <Container maxWidth="xs">
      <Grid container spacing={0} justify="center">
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <Header>Login</Header>
          </Grid>
          {renderError()}
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4}>
              <Label>Username</Label>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ValueTextField
                autoFocus
                variant="outlined"
                fullWidth
                id="username"
                name="username"
                value={username}
                onChange={(e) => {setUsername(e.target.value)}}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4}>
              <Label>Password</Label>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ValueTextField
                variant="outlined"
                fullWidth
                name="password"
                type={showPassword?'text':'password'}
                id="password"
                value={password}
                onChange={(e) => {setPassword(e.target.value)}}
                InputProps={
                  {endAdornment: 
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >{showPassword?<Visibility/>:<VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  }
                }
              />
            </Grid>
          </Grid>
          <LoginButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submit"
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} style={{ marginRight: '10px' }} />:null}
            Login
          </LoginButton>
        </form>
      </Grid>
    </Container>
  );
}
