import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress, Slider } from '@material-ui/core';
import { CheckCircleOutlineSharp, HighlightOffSharp } from '@material-ui/icons';
import { InstallPaper, InstallBox, InstallSwitch, Header, Label, RefreshButton } from './parts';
import { getGridSetting, sendGridSetting } from '../../../actions/installation/gridActions'

const StatusIcon = ({ status }) => {
    return status ? <CheckCircleOutlineSharp style={{ color: "green" }} /> : <HighlightOffSharp style={{ color: "red" }} />
}


export function GridExport({ readonly }) {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.installation.grid.setting);
  const loading = useSelector(state => state.installation.grid.settingLoading);
  const [sliderValue, setSliderValue] = useState(0);
  const setting = settings.find(x => x.parameter === 'max_export');
  const enabled = setting?setting.data.value!=="0":false;
  const sliderMarks = [{value: 0, label: '0%'}, {value: 50, label: '50%'}, {value: 100, label: '100%'}];
    

  useEffect(() => {
    dispatch(getGridSetting('max_export'));
  }, [dispatch]);

  useEffect(() => {
    if (setting) {
      if (Math.abs(setting.data.value) <= 50) {
        setSliderValue(0);
      } else {
        setSliderValue(Math.abs(setting.data.value)/50);
      }
    } 
  }, [settings, setting]);

  function handleRefresh() {
    dispatch(getGridSetting('max_export'));
  }

  function handleSwitch(e) {
    dispatch(sendGridSetting(setting.parameter, {value: (e.target.checked?5000:0)}));
  }

  function handleSlider(value) {
    if (value === 0) {
      dispatch(sendGridSetting(setting.parameter, {value: 50}));
    } else {
      dispatch(sendGridSetting(setting.parameter, {value: value*50}));
    }
  }

  function renderValue() {
    if (!setting) {
      return null;
    } else if (Math.abs(setting.data.value) <= 50) {
      return 0
    }
    return (
      setting.data.value
    );
  }

  return (
    <InstallPaper>
      <Header>
        Export Limit
        {loading?<CircularProgress size={16}/>:<RefreshButton onClick={handleRefresh}/>}
      </Header>
      <InstallBox>
        <Grid container justify="space-between" alignItems="center" spacing={0}>
          <Grid item xs={9}>
            <Label>Enable</Label>
          </Grid>
          <Grid item xs={3} align="right">
            {setting ? <>
              {readonly ? 
                <StatusIcon status={setting.data.value === 'on'} /> : 
                <InstallSwitch
                  name={setting.data.id}
                  size="small"
                  checked={enabled}
                  onChange={(e) => handleSwitch(e)}
                  disabled={loading}
                />
              }
            </>
             : null}
          </Grid>
        </Grid>
      </InstallBox>
      {enabled?<InstallBox>
        <Grid container justify="space-between" alignItems="center" spacing={0}>
          <Grid item xs={6}>
            <Label>Limit</Label>
          </Grid>
          <Grid item xs={4}>
            <Slider
              value={sliderValue}
              step={5}
              min={0}
              max={100}
              marks={sliderMarks}
              valueLabelDisplay="auto"
              onChange={(e, val) => setSliderValue(val)}
              onChangeCommitted={(e, val) => handleSlider(val)}
              disabled={loading || readonly}
            />
          </Grid>
          <Grid item align="right" xs={2}>
            {loading?<CircularProgress size={16}/>
              :<Label>{renderValue()} W</Label>}
          </Grid>
        </Grid>
      </InstallBox>:null}
    </InstallPaper>
  );
};
