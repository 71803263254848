import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { InputBase, MenuItem, Select } from "@material-ui/core";


const OpMenuItem = withStyles({
  root: {
    fontSize: "13px",
    color: "#102d39",
  },
  selected: {
    color: "#fff",
    backgroundColor: "#102d39",
    "&:focus": {
      color: "#fff",
      backgroundColor: "#102d39",
    },
    "&:hover": {
      color: "#fff",
      backgroundColor: "#102d39",
    },
  },
})(MenuItem);

const OpInput = withStyles({
  root: {
    width: "100%",
    color: "#fff"
  },
  input: {
    textAlign: 'left',
    borderRadius: 3,
    backgroundColor: "#102d39",
    border: "1px solid #102d39",
    fontSize: "13px",
    padding: "3px 10px 3px 10px",
    "&:focus": {
      backgroundColor: "#102d39",
      borderRadius: "3px",
      borderColor: "#102d39"
    }
  }
})(InputBase);

const OpSelect = withStyles({
  root: {
    color: "#fff",
  },
  icon: {
    color: "#fab529",
    fontSize: "35px",
    marginTop: "-7px",
  },
  selectMenu: {
    width: "100%",
  },
})(Select);

export class InstallSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      label,
      value,
      options,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <OpSelect
          id={label}
          label={label}
          value={value}
          input={<OpInput />}
          {...rest}
        >
          {options.map((item, index) =>
            <OpMenuItem key={label+index} value={item.value}>{item.label}</OpMenuItem>
          )}
        </OpSelect>
      </React.Fragment>
    );
  }
}
