import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from "react-router-dom";
import { styled } from '@material-ui/core/styles';
import { Box, Container, Grid } from '@material-ui/core';
import { userRedirectDone } from '../../actions/userActions'
import TopMenu from './TopMenu';
import SubMenu from './SubMenu';
import StatusIcon from '../../components/Status/StatusIcon';
import SystemMessageIndicator from './SystemMessageIndicator';
import './topbar.css';

const Bar = styled(Box)({
  minHeight: '94px',
  width: '100%',
  color: '#fff',
  background: '#42596b',
  border: 'none',
  padding: '0px',
});

const Logo = styled(Box)({
  float: 'left',
  margin: '20px 0',
  height: '54px',
});

const Serial = styled(Box)({
  color: 'inherit',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  minWidth: '120px',
  marginTop: '10px',
  marginLeft: '20px'
});

const User = styled(Box)({
  color: 'inherit',
  fontSize: '15px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textTransform: 'capitalize',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  minWidth: '120px',
  marginTop: '38px',
  marginLeft: '20px'
});

const TopBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const name = useSelector(state => state.user.name);
  const links = useSelector(state => state.user.links);
  const serial = useSelector(state => state.user.serial);
  const redirect = useSelector(state => state.user.redirect);
  const loggedIn = useSelector(state => state.user.loggedIn);
  const path = useSelector(state => state.router.location.pathname);
  const errorCount = useSelector(state => state.event.errorCount);
  const doLogout = useSelector(state => state.event.doLogout);

  useEffect(() => {
    if (redirect) {
      dispatch(userRedirectDone());
    }
  }, [redirect, dispatch]);

  useEffect(() => {
    if (doLogout) {
      history.push('/logout');
    }
  }, [doLogout, history]);

  var subMenu = null;
  var subMenuLinks = null;
  if (links) {
    for (const key of Object.keys(links)) {
      var re = new RegExp("^/"+key, "g");
      if (re.test(path)) {
        subMenu = key;
        subMenuLinks = links[key];
      } 
    }
  }

  if (errorCount > 2) {
    return (null);
  }

  function renderSubMenu() {
    if (subMenu) {
      return(
        <Grid item xs={12}>
          <SubMenu menu={subMenu} links={subMenuLinks} />
        </Grid>
      );
    }
  }

  if (redirect) {
    console.log("redirect");
    return <Redirect to='/' />;
  }

  return (
    <Bar id="topbar">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} className="top-container">
            <Logo component="img" src="/static/images/logo.png"></Logo>
            <SystemMessageIndicator />
            <div className="top-right">
              <StatusIcon />
              <div className="name">
                {loggedIn && <User component="span" >Hello, {name}</User>}
                {serial && <Serial component="span" >{serial}</Serial>}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <TopMenu links={links?Object.keys(links):['operation']} loggedIn={loggedIn} />
          </Grid>
        </Grid>
      </Container>
      {renderSubMenu()}
    </Bar>
  );
}

export default TopBar;
