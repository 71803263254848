import API from '../../api';

// Create Redux action types
export const GET_DEBUG_STATUS_REQUEST = 'GET_DEBUG_STATUS_REQUEST';
export const GET_DEBUG_STATUS_SUCCESS = 'GET_DEBUG_STATUS_SUCCESS';
export const GET_DEBUG_STATUS_FAILURE = 'GET_DEBUG_STATUS_FAILURE';

// Create Redux action creators that return an action
export const getDebugStatusRequest = () => ({
  type: GET_DEBUG_STATUS_REQUEST,
})

export const getDebugStatusSuccess = (data) => ({
  type: GET_DEBUG_STATUS_SUCCESS,
  payload: data,
})

export const getDebugStatusFailure = () => ({
  type: GET_DEBUG_STATUS_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchDebugStatus() {
  return async (dispatch) => {
    dispatch(getDebugStatusRequest())
    try {
      const response = await API.get('/api/status/');
      dispatch(getDebugStatusSuccess(response.data))
    } catch (error) {
      dispatch(getDebugStatusFailure())
    }
  }
}
