import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// ========================================

class SignalSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, value) {
    let added = value.filter(n => !this.props.values.includes(n));
    let removed = this.props.values.filter(n => !value.includes(n));
    if (added.length) {
      this.props.onSelect(added[0]);
    }
    if (removed.length) {
      this.props.onDeselect(removed[0]);
    }
  }

  render() {
    const {
      id,
      options,
      values,
      loading
    } = this.props;

    return (
      <React.Fragment>
        <Autocomplete
          multiple
          id={"debug-signals"+id}
          options={options.sort((a, b) => -b.group.localeCompare(a.group))}
          groupBy={option => option.group.replace(/_/g, ' ').toUpperCase()}
          getOptionLabel={option => option.display_name}
          filterSelectedOptions
          onChange={this.handleChange}
          value={values}
          disabled={loading}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select signal"
              fullWidth
            />
          )}
        />
      </React.Fragment>
    );
  }
}

// ========================================

export default SignalSelect;
