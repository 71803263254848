import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Container, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { NetworkStatus, WifiApList, WifiSetting, WifiSignal } from './components';
import { getNetworkSetting,
         putNetworkSetting,
         getNetworkSignal,
         getNetworkStatus,
         getNetworkApList,
         clearNetworkSettingError } from '../../actions/networkActions'

// ========================================

export default function NetworkPage () {
  const dispatch = useDispatch();
  const data = useSelector(state => state.network);
  const setting = useSelector(state => state.network.setting);

  useEffect (() => {
      dispatch(getNetworkSetting());
      dispatch(getNetworkApList());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getWifiStatus());
  //   const intervalStatus = setInterval(() => {
  //     dispatch(getWifiStatus());
  //   }, 10000);
  //   return () => clearInterval(intervalStatus);
  // }, [dispatch]);

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container justify="space-between" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <NetworkStatus
              refresh={() => dispatch(getNetworkStatus())}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <WifiSetting
              refresh={() => dispatch(getNetworkSetting())}
              clear={() => dispatch(clearNetworkSettingError())}
            />         
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <WifiApList refresh={() => dispatch(getNetworkApList())}/>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
