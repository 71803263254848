import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { WifiOff, SettingsEthernet, ReportProblemOutlined } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { getNetworkSignal, getNetworkStatus} from '../../actions/networkActions'


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

const useStyles = makeStyles(theme => ({
    icon: {
        cursor: 'pointer',
        color: '#2196f3'
    }
}));

const lte_bars = (rsrp, snr) => {
    var rsrp_bars = 0;
    var snr_bars = 0;
    // Convert to integer
    rsrp = rsrp?parseFloat(rsrp.split(' ')[0]):-200;
    snr = snr?parseFloat(snr.split(' ')[0]):-100;
    // Calculate bars based on RSRP
    if(rsrp > -85) rsrp_bars = 5;
    if(rsrp > -95 && rsrp <= -85) rsrp_bars = 4;
    if(rsrp > -105 && rsrp <= -95) rsrp_bars = 3;
    if(rsrp > -115 && rsrp <= -105) rsrp_bars = 2;
    if(rsrp > -199 && rsrp <= -115) rsrp_bars = 1;
    // Calculate bars based on SNR
    if(snr >= 13) snr_bars = 5;
    if(snr >= 4.5 && snr < 13) snr_bars = 4; 
    if(snr >= 1&& snr < 4) snr_bars = 3;
    if(snr > -3 && snr < 1) snr_bars = 2;
    if(snr > -99 && snr <= -3) snr_bars = 1;
    // Choose the later of the 2
    if(snr_bars < rsrp_bars) return snr_bars;
    return rsrp_bars;
}

const StatusIcon = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const signal = useSelector(state => state.network.signal);
    const status = useSelector(state => state.network.status);
    const base = "#00394e";
    const active = '#2196f3';
    const lte_signal = lte_bars(signal?.modem?.rsrp, signal?.modem?.snr);

    useEffect(() => {
    dispatch(getNetworkSignal());
      dispatch(getNetworkStatus());
      const interval = setInterval(() => {
      dispatch(getNetworkSignal());
      dispatch(getNetworkStatus()); 
      }, 60000);
      return () => clearInterval(interval);
    }, [dispatch]);

    return <Grid container item
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
        xs={12}
    >
        {!isEmpty(status?.connection) && (!status?.connection?.server || !status?.connection?.internet) && 
            <Grid item xs={3} className={classes.icon}>
                <HtmlTooltip placement="top" title={
                <React.Fragment>
                    {!status?.connection?.server && <Typography color="#fab529">Server Not Available</Typography>}
                    {!status?.connection?.internet && <Typography color="red">Internet Not Connected</Typography>}
                </React.Fragment>
                }>
                    <ReportProblemOutlined style={{ color: !status?.connection?.server ? '#fab529' : !status?.connection?.internet ? 'red' : 'inherit' }} />
                </HtmlTooltip>
            </Grid>
        }
        <Grid item xs={3} className={classes.icon}>
            <HtmlTooltip disableHoverListener={!status?.ethernet} placement="top" title={
                <React.Fragment>
                    {status?.ethernet?.ip && <Typography color="inherit">IP: {status?.ethernet?.ip}</Typography>}
                    {status?.ethernet?.state && <Typography color="inherit">State: {status?.ethernet?.state}</Typography>}
                </React.Fragment>
            }>
                {status?.ethernet?.ip?<SettingsEthernet />:<div />}
            </HtmlTooltip>
        </Grid>
        <Grid item xs={3} className={classes.icon}>
            <HtmlTooltip disableHoverListener={!status?.wifi} placement="top" title={
                <React.Fragment>
                    {signal?.wifi?.ssid && <Typography color="inherit">{signal?.wifi?.ssid}</Typography>}
                    {status?.wifi?.ip && <Typography color="inherit">{status?.wifi?.ip}</Typography>}
                    {signal?.wifi?.['Bit Rate'] && <Typography color="inherit">{signal?.wifi?.['Bit Rate']}</Typography>}
                </React.Fragment>
            }>
                {signal?.wifi?.['Link Quality'] === 0 ? 
                    <WifiOff style={{ color: 'red' }} /> : 
                    <svg viewBox="0 0 24 24" width="28px" height="28px">
                        <g>
                            <g>
                            <circle fill={signal?.wifi?.['Link Quality'].split('/')[0] >= 1 ? active : base} cx="12" cy="19" r="1"></circle>
                            <path
                                fill={signal?.wifi?.['Link Quality'].split('/')[0] >=2 ? active : base}
                                d="M12 14a5 5 0 00-3.47 1.4 1 1 0 101.39 1.44 3.08 3.08 0 014.16 0 1 1 0 101.39-1.44A5 5 0 0012 14z"
                            ></path>
                            <path fill={signal?.wifi?.['Link Quality'].split('/')[0] >= 3 ? active : base} d="M12 9a9 9 0 00-6.47 2.75A1 1 0 007 13.14a7 7 0 0110.08 0 1 1 0 00.71.3 1 1 0 00.72-1.69A9 9 0 0012 9z"></path>
                            <path fill={signal?.wifi?.['Link Quality'].split('/')[0] >= 4 ? active : base} d="M21.72 7.93a14 14 0 00-19.44 0 1 1 0 001.38 1.44 12 12 0 0116.68 0 1 1 0 00.69.28 1 1 0 00.72-.31 1 1 0 00-.03-1.41z"></path>
                            </g>
                        </g>
                    </svg>    
                }
            </HtmlTooltip>
        </Grid>
        <Grid item xs={3} className={classes.icon}>
            <HtmlTooltip placement="top" title={
                <React.Fragment>
                    <Typography color="inherit">{signal?.modem?.operator_name} ({signal?.modem?.access_tech})</Typography>
                    {signal?.modem?.rssi && <Typography color="inherit">{signal?.modem?.rssi}</Typography>}
                    {status?.modem?.state && <Typography color="inherit">{status?.modem?.state}</Typography>}
                </React.Fragment>
            }>
                <svg viewBox="0 0 100 100" width="28px" height="28px">
                    <g className="ldl-scale">
                        <path d="M7.5 75h12.143v10H7.5z" fill={lte_signal >= 1 ? active : base}></path>
                        <path d="M25.714 60h12.143v25H25.714z" fill={lte_signal >= 2 ? active : base}></path>
                        <path d="M43.929 45h12.143v40H43.929z" fill={lte_signal >= 3 ? active : base}></path>
                        <path d="M62.143 30h12.143v55H62.143z" fill={lte_signal >= 4 ? active : base}></path>
                    </g>
                </svg>
            </HtmlTooltip>
        </Grid>
    </Grid>
}

export default StatusIcon;
