// Import all actions
import * as actions from '../../actions/installation/assignActions'

export const initialState = {
  data: {},
  loading: false,
  error: '',
}

export function AssignReducer(state = initialState, action) {
  switch (action.type) {

    case actions.GET_INSTALL_ASSIGN_REQUEST:
    case actions.PUT_INSTALL_ASSIGN_REQUEST:
      return { ...state, loading: true }
    
    case actions.GET_INSTALL_ASSIGN_SUCCESS:
    case actions.PUT_INSTALL_ASSIGN_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: '' }
    
    case actions.GET_INSTALL_ASSIGN_FAILURE:
    case actions.PUT_INSTALL_ASSIGN_FAILURE:
      return { ...state, loading: false, error: action.payload.msg }
    case actions.PUT_INSTALL_ASSIGN_CLEAR_ERROR:
      return { ...state, error: '' }

    default:
      return state
  }
}
