import API from '../../api';

// Create Redux action types
export const GET_BMS_VALUE_REQUEST = 'GET_BMS_VALUE_REQUEST';
export const GET_BMS_VALUE_SUCCESS = 'GET_BMS_VALUE_SUCCESS';
export const GET_BMS_VALUE_FAILURE = 'GET_BMS_VALUE_FAILURE';

// Create Redux action creators that return an action
export const getBmsValue = () => ({
  type: GET_BMS_VALUE_REQUEST,
})

export const getBmsValueSuccess = (data) => ({
  type: GET_BMS_VALUE_SUCCESS,
  payload: data,
})

export const getBmsValueFailure = () => ({
  type: GET_BMS_VALUE_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchBmsValue() {
  return async (dispatch) => {
    dispatch(getBmsValue())
    try {
      const response = await API.get('/api/bms/value/');
      dispatch(getBmsValueSuccess(response.data));
    } catch (error) {
      dispatch(getBmsValueFailure());
    }
  }
}

// Create Redux action types
export const GET_BMS_INFORMATION_REQUEST = 'GET_BMS_INFORMATION_REQUEST';
export const GET_BMS_INFORMATION_SUCCESS = 'GET_BMS_INFORMATION_SUCCESS';
export const GET_BMS_INFORMATION_FAILURE = 'GET_BMS_INFORMATION_FAILURE';

// Create Redux action creators that return an action
export const getBmsInformation = () => ({
  type: GET_BMS_INFORMATION_REQUEST,
})

export const getBmsInformationSuccess = (data) => ({
  type: GET_BMS_INFORMATION_SUCCESS,
  payload: data,
})

export const getBmsInformationFailure = () => ({
  type: GET_BMS_INFORMATION_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchBmsInformation() {
  return async (dispatch) => {
    dispatch(getBmsInformation())
    try {
      const response = await API.get('/api/bms/information/');
      dispatch(getBmsInformationSuccess(response.data));
    } catch (error) {
      dispatch(getBmsInformationFailure());
    }
  }
}

// Create Redux action types
export const GET_BMS_CHIP_CONFIG_REQUEST = 'GET_BMS_CHIP_CONFIG_REQUEST';
export const GET_BMS_CHIP_CONFIG_SUCCESS = 'GET_BMS_CHIP_CONFIG_SUCCESS';
export const GET_BMS_CHIP_CONFIG_FAILURE = 'GET_BMS_CHIP_CONFIG_FAILURE';

// Create Redux action creators that return an action
export const getBmsChipConfig = () => ({
  type: GET_BMS_CHIP_CONFIG_REQUEST,
})

export const getBmsChipConfigSuccess = (data) => ({
  type: GET_BMS_CHIP_CONFIG_SUCCESS,
  payload: data,
})

export const getBmsChipConfigFailure = () => ({
  type: GET_BMS_CHIP_CONFIG_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchBmsChipConfig() {
  return async (dispatch) => {
    dispatch(getBmsChipConfig())
    try {
      const response = await API.get('/api/bms/chip/');
      dispatch(getBmsChipConfigSuccess(response.data));
    } catch (error) {
      dispatch(getBmsChipConfigFailure());
    }
  }
}

// Create Redux action types
export const PUT_BMS_COMMAND_REQUEST = 'PUT_BMS_COMMAND_REQUEST';
export const PUT_BMS_COMMAND_SUCCESS = 'PUT_BMS_COMMAND_SUCCESS';
export const PUT_BMS_COMMAND_FAILURE = 'PUT_BMS_COMMAND_FAILURE';
export const PUT_BMS_COMMAND_CLEAR_ERROR = 'PUT_BMS_COMMAND_CLEAR_ERROR';

// Create Redux action creators that return an action
export const putBmsCommand = () => ({
  type: PUT_BMS_COMMAND_REQUEST,
})

export const putBmsCommandSuccess = (data) => ({
  type: PUT_BMS_COMMAND_SUCCESS,
  payload: data,
})

export const putBmsCommandFailure = (error) => ({
  type: PUT_BMS_COMMAND_FAILURE,
  payload: error,
})

export const clearBmsCommandError = () => ({
  type: PUT_BMS_COMMAND_CLEAR_ERROR,
  payload: null,
})

// Combine them all in an asynchronous thunk
export function sendBmsCommand(data) {
  return async (dispatch) => {
    dispatch(putBmsCommand());
    try {
      const response = await API.put('/api/bms/command/', data);
      dispatch(putBmsCommandSuccess(response.data));
    } catch (error) {
      dispatch(putBmsCommandFailure(error.response.data));
    }
  }
}

// Create Redux action types
export const GET_BMS_STATUS_REQUEST = 'GET_BMS_STATUS_REQUEST';
export const GET_BMS_STATUS_SUCCESS = 'GET_BMS_STATUS_SUCCESS';
export const GET_BMS_STATUS_FAILURE = 'GET_BMS_STATUS_FAILURE';

// Create Redux action creators that return an action
export const getBmsStatus = () => ({
  type: GET_BMS_STATUS_REQUEST,
})

export const getBmsStatusSuccess = (data) => ({
  type: GET_BMS_STATUS_SUCCESS,
  payload: data,
})

export const getBmsStatusFailure = () => ({
  type: GET_BMS_STATUS_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchBmsStatus() {
  return async (dispatch) => {
    dispatch(getBmsStatus())
    try {
      const response = await API.get('/api/bms/status/');
      dispatch(getBmsStatusSuccess(response.data));
    } catch (error) {
      dispatch(getBmsStatusFailure());
    }
  }
}
