import API from '../api';

// Create Redux action types
export const GET_OPERATION_REQUEST = 'GET_OPERATION_REQUEST';
export const GET_OPERATION_SUCCESS = 'GET_OPERATION_SUCCESS';
export const GET_OPERATION_FAILURE = 'GET_OPERATION_FAILURE';

// Create Redux action creators that return an action
export const getOperation = () => ({
  type: GET_OPERATION_REQUEST,
})

export const getOperationSuccess = (data) => ({
  type: GET_OPERATION_SUCCESS,
  payload: data,
})

export const getOperationFailure = () => ({
  type: GET_OPERATION_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchOperation() {
  return async (dispatch) => {
    dispatch(getOperation())
    try {
      const response = await API.get('/api/operation/status/');
      dispatch(getOperationSuccess(response.data))
    } catch (error) {
      dispatch(getOperationFailure())
    }
  }
}

// Create Redux action types
export const PUT_OPERATION_REQUEST = 'PUT_OPERATION_REQUEST';
export const PUT_OPERATION_SUCCESS = 'PUT_OPERATION_SUCCESS';
export const PUT_OPERATION_FAILURE = 'PUT_OPERATION_FAILURE';

// Create Redux action creators that return an action
export const putOperation = () => ({
  type: PUT_OPERATION_REQUEST,
})

export const putOperationSuccess = (data) => ({
  type: PUT_OPERATION_SUCCESS,
  payload: data,
})

export const putOperationFailure = () => ({
  type: PUT_OPERATION_FAILURE,
})

// Combine them all in an asynchronous thunk
export function sendOperation(data) {
  return async (dispatch) => {
    dispatch(putOperation())
    try {
      const response = await API.put('/api/operation/status/', data);
      dispatch(putOperationSuccess(response.data));
      // dispatch(fetchOperation());
    } catch (error) {
      dispatch(putOperationFailure())
    }
  }
}

// Create Redux action types
export const GET_OPERATION_PICTURE_REQUEST = 'GET_OPERATION_PICTURE_REQUEST';
export const GET_OPERATION_PICTURE_SUCCESS = 'GET_OPERATION_PICTURE_SUCCESS';
export const GET_OPERATION_PICTURE_FAILURE = 'GET_OPERATION_PICTURE_FAILURE';

// Create Redux action creators that return an action
export const getOperationPicture = () => ({
  type: GET_OPERATION_PICTURE_REQUEST,
})

export const getOperationPictureSuccess = (data) => ({
  type: GET_OPERATION_PICTURE_SUCCESS,
  payload: data,
})

export const getOperationPictureFailure = () => ({
  type: GET_OPERATION_PICTURE_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchOperationPicture() {
  return async (dispatch) => {
    dispatch(getOperationPicture())
    try {
      const response = await API.get('/api/operation/picture/');
      dispatch(getOperationPictureSuccess(response.data))
    } catch (error) {
      dispatch(getOperationPictureFailure())
    }
  }
}

// Create Redux action types
export const PUT_CLEAR_HALT_REQUEST = 'PUT_CLEAR_HALT_REQUEST';
export const PUT_CLEAR_HALT_SUCCESS = 'PUT_CLEAR_HALT_SUCCESS';
export const PUT_CLEAR_HALT_FAILURE = 'PUT_CLEAR_HALT_FAILURE';

// Create Redux action creators that return an action
export const putClearHalt = () => ({
  type: PUT_CLEAR_HALT_REQUEST,
})

export const putClearHaltSuccess = (data) => ({
  type: PUT_CLEAR_HALT_SUCCESS,
  payload: data,
})

export const putClearHaltFailure = () => ({
  type: PUT_CLEAR_HALT_FAILURE,
})

// Combine them all in an asynchronous thunk
export function sendClearHalt() {
  return async (dispatch) => {
    dispatch(putClearHalt())
    try {
      const response = await API.put('/api/operation/clear/', {'command': 'clear'});
      dispatch(putClearHaltSuccess(response.data))
    } catch (error) {
      dispatch(putClearHaltFailure())
    }
  }
}
