import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { DebugTable, DebugTableCell } from '../Table/DebugTable';
import { DebugPaper } from '../Paper/Paper';
import { Panel,  PanelSummary, PanelDetails } from '../Panel/ExpansionPanel';
import { RefreshButton } from '../Button/IconButton';

const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

// ========================================

class KeyList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      group,
      data
    } = this.props;

    if (!data) return null;

    const keys = Object.keys(data);

    return (
      <Panel defaultExpanded={true}>
        <PanelSummary
          expandIcon={<ArrowDropDown />}
          aria-controls={group + 'content'}
          id={group}
        >{group} 
        </PanelSummary>
        <PanelDetails>
          <TableContainer component={DebugPaper}>
            <DebugTable>
              <colgroup>
                <col />
                <col />
              </colgroup>
              <TableBody>
              {keys.map(key =>
                <TableRow key={key}>
                  <DebugTableCell  component="th" scope="row">
                    {titleCase(key)}
                  </DebugTableCell>
                  <DebugTableCell align="right">
                    {Math.round(data[key]*100)/100}
                  </DebugTableCell>
                </TableRow>
              )}
              </TableBody>
            </DebugTable>
          </TableContainer>
        </PanelDetails>
      </Panel>
    );
  }
}

// ========================================

class KeyValueList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      data,
      title,
      refresh,
      loading
    } = this.props;

    if (!data) return null;

    const keys = Object.keys(data);

    return (
      <DebugPaper>
        <Grid container item
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
          xs={12}>
          <Grid item>
            {title}
          </Grid>
          <Grid item>
            {loading ? <CircularProgress size={16} />:<RefreshButton onClick={refresh}/>}
          </Grid>
        </Grid>
        {keys.map(key =>
          <KeyList
            group={key}
            key={key}
            data={data[key]}
          />
        )}
      </DebugPaper>
    );
  }
}

// ========================================

export default KeyValueList;