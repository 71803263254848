import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import thunk from "redux-thunk";

import upgradeReducer from './reducers/upgradeReducer'
import userReducer from './reducers/userReducer'
import eventReducer from './reducers/eventReducer'
import networkReducer from './reducers/networkReducer'
import operationReducer from './reducers/operationReducer'
import logsReducer from './reducers/logsReducer'
import installationReducer from './reducers/installation'
import debugReducer from './reducers/debug'

// import crossBrowserListener from './utils/reduxpersist-listener'

const configureStore = (preloadedState) => {
  const history = createBrowserHistory();
  const rootReducer = combineReducers({
    router: connectRouter(history),
    upgrade: upgradeReducer,
    user: userReducer,
    event: eventReducer,
    network: networkReducer,
    operation: operationReducer,
    logs: logsReducer,
    installation: installationReducer,
    debug: debugReducer,
  });
  const persistConfig = {
    key: 'user',
    storage: storage,
    stateReconciler: hardSet,
    whitelist: ['user'] // which reducer want to store
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    persistedReducer,
    compose(applyMiddleware(thunk)),
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
      ),
    ),
  );
  const persistor = persistStore(store);
  // window.addEventListener('storage', crossBrowserListener(store, persistConfig));
  return {history, persistor, store};
};

export default configureStore;

