import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from '@material-ui/core';
import { ErrorOutlineOutlined, ReportProblemOutlined, InfoOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { fetchEvents } from '../../actions/eventActions'
import './topbar.css';

const ERROR = 'Error';
const WARNING = 'Warning';
const INFORMATION = 'Information';

const SystemMessageIndicator = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [systemLabel, setSystemLabel] = useState(ERROR);
    const active = useSelector(state => state.event.active);
    const errorCount = useSelector(state => state.event.errorCount);
    const loading = useSelector(state => state.event.loading);

    useEffect(() => {
      if (!loading && errorCount < 3) {
        const timeoutEvent = setTimeout(() => {
          dispatch(fetchEvents());
        }, 3000);
        return () => clearTimeout(timeoutEvent);
      }
    }, [loading, errorCount, dispatch]);

    useEffect(() => {
        let error = 0;
        let warning = 0;
        let info = 0;
        active.forEach(item => {
          if (item.severity < 4) {
            error = error + 1;
          } else if (item.severity < 6) {
            warning = warning + 1;
          } else if (item.severity <= 7) {
            info = info + 1;
          }
        });
    
        if (error >= warning && error >= info) {
          setSystemLabel(ERROR);
        } else if (warning >= info) {
          setSystemLabel(WARNING);
        } else {
          setSystemLabel(INFORMATION);
        }
    }, [active]);
    
    const openAlerts = (event) => {
    setAnchorEl(event.currentTarget);
    };

    const onCloseAlerts = () => {
    setAnchorEl(null);
    };
    
    const getSeverity = severity => {
    if (severity < 4) {
        return 'error';
    } else if (severity < 6) {
        return 'warning';
    } else if (severity <= 7) {
        return 'info';
    } else {
        return 'success';
    }
    }

    const renderIcon = () => {
    switch(systemLabel) {
        case ERROR: return <ErrorOutlineOutlined className="topIcon errorIcon" onClick={openAlerts} />;
        case WARNING: return <ReportProblemOutlined className="topIcon warningIcon" onClick={openAlerts} />;
        case INFORMATION: return <InfoOutlined className="topIcon infoIcon" onClick={openAlerts} />;
        default: return <ErrorOutlineOutlined className="topIcon warningIcon" onClick={openAlerts} />;
    }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return <div>
        {active.length > 0 && <div className="warningLabel">
              {renderIcon()} <span>System {systemLabel}</span>
        </div>}

        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onCloseAlerts}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            className="alertContainer"
        >
            {active.map((dataItem, i) => {
            return <Alert key={i} className="alertbanner" severity={getSeverity(dataItem.severity)}>                    
            <span><strong>{dataItem.message_set}!</strong></span>
            </Alert>
            })}
        </Popover>
    </div>
}

export default SystemMessageIndicator;
