import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DebugPaper } from '../Paper/Paper';
import { CommandButton } from '../Button/Button';

const buttonStyle = {
  marginTop: '50px',
};

// ========================================

class ButtonList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, command, code) {
    e.preventDefault();
    this.props.command({"command": command, "code": code})
  }

  renderAlert() {
    const {
      clear,
      error
    } = this.props;

    if (error) {
      return (
        <Alert severity="error" onClose={clear}>
          Command Failed!
        </Alert>
      )
    }

    return(null);
  }

  render() {
    const {
      loading
    } = this.props;

    return (
      <DebugPaper>
        <Grid container item
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
          xs={12}>
          <Grid item>
            Commands
          </Grid>
          <Grid item>
            {loading ? <CircularProgress size={20} />:null}
          </Grid>
        </Grid>
     
        <Grid container item
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
          style={buttonStyle}
          md={12} sm={12} xs={12}
        >
          <Grid item xs={12}>
            {this.renderAlert()}
          </Grid>
          <Grid item xs={12}>
            BMS
          </Grid>
          <Grid item xs={6}>
            <CommandButton            
              disabled={loading} 
              onClick={e => this.handleClick(e, "reload")}>
              Reload
            </CommandButton>
          </Grid>
          <Grid item xs={6}>
            <CommandButton 
              disabled={loading} 
              onClick={e => this.handleClick(e, "refresh")}>
              Refresh
            </CommandButton>
          </Grid>
          <Grid item xs={12}>
            <CommandButton 
              disabled={loading} 
              onClick={e => this.handleClick(e, "fault_override")}>
              Override Faults
            </CommandButton>
          </Grid>
          <Grid item xs={12}>
            <CommandButton 
              disabled={loading} 
              onClick={e => this.handleClick(e, "balancing_lockout")}>
              Lockout Balancing
            </CommandButton>
          </Grid>
          <Grid item xs={12}>
            <CommandButton 
              disabled={loading} 
              onClick={e => this.handleClick(e, "balancing_force")}>
              Force Balancing
            </CommandButton>
          </Grid>          
          <Grid item xs={12}>
            <CommandButton 
              disabled={loading} 
              onClick={e => this.handleClick(e, "chip_setting")}>
              Chip Setting
            </CommandButton>
          </Grid>
          <Grid item xs={12}>
            <CommandButton 
              disabled={loading} 
              onClick={e => this.handleClick(e, "clear_faults")}>
              Clear Faults
            </CommandButton>
          </Grid>
        </Grid>
      </DebugPaper>
    );
  }
}

// ========================================

export default ButtonList;