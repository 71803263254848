import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container';
import { fetchSignalsOptions,
         signalsAddGraph,
         signalsRemoveGraph,
         fetchSignalsLoad,
         signalsRemoveSeries } from '../../actions/debug/signalsActions'
import Signals from '../../components/Debug/Signals';

// ========================================

export function DebugSignalsPage () {
  const dispatch = useDispatch()
  const graphs = useSelector(state => state.debug.signals.graphs)
  const options = useSelector(state => state.debug.signals.options)
  const loading = useSelector(state => state.debug.signals.loading)
  const adcClock = useSelector(state => state.debug.signals.adcClock)
  const clockDivider = useSelector(state => state.debug.signals.clockDivider)

  useEffect(() => {
    dispatch(fetchSignalsOptions());
  }, [dispatch]);
 
  return (
    <Container maxWidth="xl">
      <Signals
        adcClock={adcClock}
        clockDivider={clockDivider}
        graphs={graphs}
        options={options}
        loading={loading}
        addGraph={() => dispatch(signalsAddGraph())}
        removeGraph={(id) => dispatch(signalsRemoveGraph(id))}
        addSeries={(id, key) => dispatch(fetchSignalsLoad(id, key))}
        removeSeries={(id, key) => dispatch(signalsRemoveSeries(id, key))}
      /> 
    </Container>
  );

}

