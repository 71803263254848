import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

export const InstallSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#003f56',
    },
    '&$checked + $track': {
      backgroundColor: '#003f56',
    },
  },
  checked: {},
  track: {},
})(Switch);
