// Import all actions
import * as actions from '../actions/eventActions'
import { sortBy } from 'lodash';


export const initialState = {
  authenticated: false,
  active: [],
  loading: false,
  errorCount: 0,
  error: false,
  doLogout: false
}

export default function eventReducer(state = initialState, action) {
  switch (action.type) {

    case actions.GET_EVENT_DISPLAY_REQUEST:
      return { ...state, loading: true }
    case actions.GET_EVENT_DISPLAY_SUCCESS:
      return {
        ...state,
        authenticated: action.payload.authenticated,
        active: sortBy(action.payload.active, ['severity']),
        loading: false,
        errorCount: 0,
        doLogout: state.authenticated && !action.payload.authenticated
      }
    case actions.GET_EVENT_DISPLAY_FAILURE:
      return { ...state, loading: false, errorCount: state.errorCount+1 }

    case actions.SET_DO_LOGOUT: {
      return {
        ...state,
        doLogout: action.payload
      }
    }
    
    default:
      return state
  }
}


