import React from 'react';
import { isNil } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const MultiStepLine = ({ type }) => {
  const lgQuery = useMediaQuery('(min-width:1440px)');
  if (isNil(type)) return null;
  const leftPath = lgQuery ? '10,50 10,30 380,30 380,0' : '10,50 10,30 280,30 280,0';
  const rightPath = lgQuery ? '10,60 10,35 380,35 380,15' : '10,60 10,35 280,35 280,15';
  const leftMovePath = lgQuery ? 'M380,0 380,30 10,30 10,50' : 'M280,0 280,30 10,30 10,50';
  const rightMovePath = lgQuery ? 'M10,60 10,35 380,35 380,15' : 'M10,60 10,35 280,35 280,15';
  return (<svg height='65px' width={lgQuery ? '450px' : '350px'} style={{ position: 'absolute', left: '50%', top: '-70px' }}>
  <defs>
    <marker id="startarrow" markerWidth="8" markerHeight="10" 
      refX="8" refY="5" orient="auto">
      <polygon points="8 0, 8 10, 0 5" fill="rgb(249, 181, 40)" />
    </marker>
    <marker id="endarrow" markerWidth="8" markerHeight="10" 
      refX="0" refY="5" orient="auto">
      <polygon points="0 0, 8 5, 0 10" fill="rgb(249, 181, 40)" />
    </marker>
    <filter id="shadow">
      <feDropShadow dx="0" dy="0" stdDeviation="3"
          floodColor="rgb(249, 181, 40)"/>
    </filter>
  </defs>
  <polyline 
    fill="none" 
    stroke="rgb(249, 181, 40)" 
    strokeWidth="2" 
    strokeDasharray="5,6" 
    strokeLinecap="round" 
    points={type === 'right' ? rightPath : leftPath}
    markerStart={type === 'left' ? 'url(#startarrow)' : ''} 
    markerEnd={type === 'right' ? 'url(#endarrow)' : ''} />

  <circle r="8" fill="rgb(249, 181, 40)" filter="url(#shadow)">
    <animateMotion dur="2s" repeatCount="indefinite"
    path={type === 'right' ? rightMovePath : leftMovePath} />
  </circle>
</svg>
)}
export default MultiStepLine;
