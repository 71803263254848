// Import all actions
import * as actions from '../../actions/debug/bmsActions'

export const initialState = {
  value: {},
  information: {},
  chipConfig: {},
  status: {},
  valueLoading: false,
  informationLoading: false,
  chipConfigLoading: false,
  commandLoading: false,
  statusloading: false,
  error: false,
  commandError: false,
}

export function BmsReducer(state = initialState, action) {
  switch (action.type) {

    case actions.GET_BMS_VALUE_REQUEST:
      return { ...state, valueLoading: true }
    case actions.GET_BMS_VALUE_SUCCESS:
      return { ...state, value: action.payload, valueLoading: false, error: false }
    case actions.GET_BMS_VALUE_FAILURE:
      return { ...state, valueLoading: false, error: true }

    case actions.GET_BMS_INFORMATION_REQUEST:
      return { ...state, informationLoading: true }
    case actions.GET_BMS_INFORMATION_SUCCESS:
      return { ...state, information: action.payload, informationLoading: false, error: false }
    case actions.GET_BMS_INFORMATION_FAILURE:
      return { ...state, informationLoading: false, error: true }

    case actions.GET_BMS_CHIP_CONFIG_REQUEST:
      return { ...state, chipConfigLoading: true }
    case actions.GET_BMS_CHIP_CONFIG_SUCCESS:
      return { ...state, chipConfig: action.payload, chipConfigLoading: false, error: false }
    case actions.GET_BMS_CHIP_CONFIG_FAILURE:
      return { ...state, chipConfigLoading: false, error: true }

    case actions.PUT_BMS_COMMAND_REQUEST:
      return { ...state, commandLoading: true }
    case actions.PUT_BMS_COMMAND_SUCCESS:
      return { ...state, commandLoading: false, error: false }
    case actions.PUT_BMS_COMMAND_FAILURE:
      return { ...state, commandLoading: false, commandError: true }
    case actions.PUT_BMS_COMMAND_CLEAR_ERROR:
      return { ...state, commandError: false }

    case actions.GET_BMS_STATUS_REQUEST:
      return { ...state, statusLoading: true }
    case actions.GET_BMS_STATUS_SUCCESS:
      return { ...state, status: action.payload, statusLoading: false, error: false }
    case actions.GET_BMS_STATUS_FAILURE:
      return { ...state, statusLoading: false, error: true }

    default:
      return state
  }
}
