import React from 'react';
import { Grid, Box, Button, CircularProgress } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { OperationTitle } from '../Typography/Title'
import { StatusLabel, StatusValue } from '../Typography/Label'
import OperationSelect from '../Select/OperationSelect';
import _ from 'lodash';

import './operation.css';

const StatusBox = styled(Box)({
  maxWidth: '800px',
});

const UpdateButton = styled(Button)({
  background: '#003f56',
  color: 'inherit',
  height: '40px',
  width: "100%",
  borderRadius: '3px',
  border: 'solid 1px #102d39',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  letterSpacing: '1px',
  '&:hover': {
    background: '#102d39',
  },
});

const ClearButton = styled(Button)({
  background: '#003f56',
  color: 'inherit',
  height: '40px',
  width: "100%",
  borderRadius: '3px',
  border: 'solid 1px #102d39',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  letterSpacing: '1px',
  '&:hover': {
    background: '#CC0000',
    border: 'solid 1px #CC0000',
  },
});

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      localState: this.props.data
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDismissAlert = this.handleDismissAlert.bind(this);
  } 
  
  touched() {
    return !_.isEqual(this.state.localState, this.props.data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      if (!this.touched() || this.localState === undefined) {
        this.setState({ localState: nextProps.data });
      } else {
        this.setState({ showAlert: true });
      }
    }
  }

  handleUpdate(e) {
    e.preventDefault();
    this.props.update(this.state.localState);
    // this.setState({ localState: this.props.data });
  }

  handleModeChange(e) {
    this.setState(prevState => (
      { localState: { ...prevState.localState, mode: e.target.value } }
    ));
  }

  handleSelectChange(e) {
    this.setState(prevState => (
      { localState: { ...prevState.localState, [e.target.name]: e.target.value } }
    ));
  }

  handleDismissAlert() {
    this.setState({ localState: this.props.data , showAlert : false });
  }

  renderAlert() {
    if (this.state.showAlert) {
      return (
        <Alert severity="warning">
            The Elektrobank settings have been updated on the device since you made your changes. Please click ok to view the updated device settings.
            <Button onClick={this.handleDismissAlert}>Ok</Button>
        </Alert>
      )
    }
  }

  renderStatus() {
    const {
      localState,
      showAlert
    } = this.state;

    if (localState === undefined) {
      return(
        null
      );
    }

    if ((localState.mode === "auto") && !showAlert && !this.touched()) {
      return (
        <React.Fragment>  
          <Grid item xs={4}>
            <StatusLabel>Schedule</StatusLabel>
          </Grid> 
          <Grid item xs={8}>
            <StatusValue>{localState.running}</StatusValue>
          </Grid>
          <Grid item xs={4}>
            <StatusLabel>Entry</StatusLabel>
          </Grid> 
          <Grid item xs={8}>
            <StatusValue>{localState.entry}</StatusValue>
          </Grid>
          <Grid item xs={4}>
            <StatusLabel>Profile</StatusLabel>
          </Grid>
          <Grid item xs={8}>
            <StatusValue>{localState.profile}</StatusValue>
          </Grid>
          <Grid item xs={4}>
            <StatusLabel>Active Rule</StatusLabel>
          </Grid>
          <Grid item xs={8}>
            <StatusValue>{localState.rule}</StatusValue>
          </Grid>
        </React.Fragment>
      )
    }
  }

  renderButton() {
    const {
      loggedIn,
      updating,
      clearHalt
    } = this.props;
    const {
      localState,
      showAlert
    } = this.state;

    if (loggedIn  && !updating) {
      if (this.touched() && !showAlert && localState.halted) {
        return (
          <React.Fragment>
            <Grid item xs={6}>
              <UpdateButton variant="outlined" onClick={e => this.handleUpdate(e)}>Apply</UpdateButton>
            </Grid>
            <Grid item xs={6}>
              <ClearButton variant="outlined" onClick={clearHalt}>Clear Halt</ClearButton>
            </Grid>
          </React.Fragment>
        )
      } else if (this.touched() && !showAlert) {
        return (
          <Grid item xs={12}>
            <UpdateButton variant="outlined" onClick={e => this.handleUpdate(e)}>Apply Changes</UpdateButton>
          </Grid>
        )
      } else if (localState.halted) {
        return (
          <Grid item xs={12}>
            <ClearButton variant="outlined" onClick={clearHalt}>Clear Halt</ClearButton>
          </Grid>
        )
      }
    }
  }

  renderSelects() {
    const {
      loggedIn,
      options,
      updating
    } = this.props;
    const {
      showAlert,
      localState
    } = this.state;

    if (localState === undefined) {
      return(
        null
      );
    }

    if ((localState.mode === "manual") && (!showAlert)) {
      return (
        <React.Fragment>
          <OperationSelect
            label="system"
            name="system"
            value={localState.system}
            onChange={this.handleSelectChange}
            options={options.system}
            disabled={updating||!loggedIn}
          />
          <OperationSelect
            label="battery"
            name="battery"
            value={localState.battery}
            onChange={this.handleSelectChange}
            options={options.battery}
            disabled={updating||!loggedIn}
          />
          <OperationSelect
            label="battery max"
            name="limit"
            value={localState.limit}
            onChange={this.handleSelectChange}
            options={options.limit}
            disabled={updating||!loggedIn}
          />
        </React.Fragment>
      )
    }
  }

  render() {
    const {
      loggedIn,
      options,
      loading,
      updating
    } = this.props;
    const {
      localState
    } = this.state;

    if (localState === undefined) {
      return (
        <CircularProgress size={20} />
      );
    }    

    return (
      <Grid container justify="center">  
        <StatusBox>
          <Grid container item
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
            xs={12}
          >
            <Grid item xs={12}>
              <OperationTitle component="div" className="titleSection">
                System Status                
                {(loading||updating)? <CircularProgress size={20} />:null}                
              </OperationTitle>
            </Grid>
            <Grid item xs={12}>
              {this.renderAlert()}
            </Grid>
            <OperationSelect
              label="operation"
              name="operation"
              value={localState.mode}
              onChange={this.handleModeChange}
              options={options.mode}
              disabled={updating||!loggedIn}
            />
            {this.renderSelects()}
            {this.renderStatus()}
            {this.renderButton()}         
          </Grid>
        </StatusBox>
      </Grid>
    );
  }
}

export default Status;
