import React from 'react';
import { NavLink } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import './SubMenu.css'

const SubMenu = ({ menu, links }) => {
  if (links.length) {
    return (
      <div className="SubBar">
        <Container maxWidth="lg">
          <Grid container item>
            <Grid item xs={12} className="SubMenu">
            {links.map(item =>
              <NavLink key={"submenu"+item}
                className="SubMenuItem"
                to={"/"+menu+"/"+item}
                >{item}
              </NavLink>
            )}
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  return <div/>;
}

// ========================================

export default SubMenu;
