import API from '../../api';

export const GET_INSTALL_ASSIGN_REQUEST = 'GET_INSTALL_ASSIGN_REQUEST';
export const GET_INSTALL_ASSIGN_SUCCESS = 'GET_INSTALL_ASSIGN_SUCCESS';
export const GET_INSTALL_ASSIGN_FAILURE = 'GET_INSTALL_ASSIGN_FAILURE';

export const getInstallAssignRequest = () => ({
  type: GET_INSTALL_ASSIGN_REQUEST,
})

export const getInstallAssignSuccess = (data) => ({
  type: GET_INSTALL_ASSIGN_SUCCESS,
  payload: data,
})

export const getInstallAssignFailure = (data) => ({
  type: GET_INSTALL_ASSIGN_FAILURE,
  payload: data,
})

export function getInstallAssign() {
  return async (dispatch) => {
    dispatch(getInstallAssignRequest())
    try {
      const response = await API.get('/api/installation/assign/');
      dispatch(getInstallAssignSuccess(response?.data));
    } catch (error) {
      dispatch(getInstallAssignFailure(error.response.data));
    }
  }
}

export const PUT_INSTALL_ASSIGN_REQUEST = 'PUT_INSTALL_ASSIGN_REQUEST';
export const PUT_INSTALL_ASSIGN_SUCCESS = 'PUT_INSTALL_ASSIGN_SUCCESS';
export const PUT_INSTALL_ASSIGN_FAILURE = 'PUT_INSTALL_ASSIGN_FAILURE';
export const PUT_INSTALL_ASSIGN_CLEAR_ERROR = 'PUT_INSTALL_ASSIGN_CLEAR_ERROR';

export const putInstallAssignRequest = () => ({
  type: PUT_INSTALL_ASSIGN_REQUEST,
})

export const putInstallAssignSuccess = (data) => ({
  type: PUT_INSTALL_ASSIGN_SUCCESS,
  payload: data,
})

export const putInstallAssignFailure = (data) => ({
  type: PUT_INSTALL_ASSIGN_FAILURE,
  payload: data,
})

export const clearInstallAssignError = () => ({
  type: PUT_INSTALL_ASSIGN_CLEAR_ERROR,
  payload: null,
})

export function sendInstallAssign(data) {
  return async (dispatch) => {
    dispatch(putInstallAssignRequest());
    try {
      const response = await API.put(`/api/installation/assign/`, data);
      dispatch(putInstallAssignSuccess(response.data));
    } catch (error) {
      dispatch(putInstallAssignFailure(error.response.data));
    }
  }
}
