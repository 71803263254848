import React from 'react';
import ReactHighcharts from 'react-highcharts';
import Grid from '@material-ui/core/Grid';
import SignalSelect from '../Select/SignalSelect';
import { DeleteButton } from '../Button/IconButton';


class DebugGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDeselect = this.handleDeselect.bind(this);
  }

  handleSelect(value) {
    this.props.addSeries(this.props.id, value);
  }

  handleDeselect(value) {
    this.props.removeSeries(this.props.id, value);
  }

  render() {
    const {
      id,
      series,
      values,
      clockDivider,
      adcClock,
      onRemove,
      options,
      loading
    } = this.props;

    const config = {
      chart: {
          type: 'spline',
          zoomType: 'x',
          marginLeft: 100,
          backgroundColor: '#fafafa',
          style: {"fontFamily":"\"HelveticaNeue-Light\", \"Helvetica Neue Light\", HelveticaNeue, \"Helvetica Neue\", Helvetica, Arial, , \"Lucida Grande\", sans-serif", "fontSize":"12px"},
      },
      title: {
          text: ''
      },
      xAxis: {
          //type: 'datetime',
          //min: start,
          //max: end,
          /*dateTimeLabelFormats: { // don't display the dummy year
              month: '%e. %b',
              year: '%b'
          },*/
          //lineWidth: 0,
          //tickWidth: 0,
          labels: {
            style: {
              color: '#004057',
              fontSize: '12px'
            }
          }
      },
      yAxis: {
          //min: 0,
          gridLineWidth: 0,
          title: {
              text: '',
              align: 'high',
              offset: 15,
              rotation: 0,
              reserveSpace: true,
              y: -30,
              style: {
                color: '#004057',
                fontSize: '12px'
              }
          },
          labels: {
            style: {
              color: '#004057',
              fontSize: '14px'
            }
          }
      },
      legend: {
          verticalAlign: 'top',
          floating: false,
          itemMarginBottom: 10,
          backgroundColor: 'white',
          borderColor: '#CCC',
          borderWidth: 0,
          itemStyle: {
            color: '#004057',
            fontSize: '12px',
            fontWeight: 'normal'
          },
          itemHiddenStyle:{
            color: '#606060'
          },
          // itemWidth: 135,
          squareSymbol: false,
          symbolHeight: 14,
          symbolWidth: 30,
          symbolPadding: 10,
          symbolRadius: 3,
          shadow: false
      },
      tooltip: {
          headerFormat: '<b>Time:</b> {point.x:.5f}<br>',
          pointFormat: '<b>{series.name}:</b> {point.y:.3f}<br>',
          shared: true
          //pointFormat: '{point.x:%e. %b}: {point.y:.2f} m'
      },
      plotOptions: {
        series: {
          marker: {
            enabled: null
          },
          pointStart: 0,
          pointInterval: clockDivider/(adcClock/2)
        },
        spline: {
            marker: {
                enabled: true
            }
        }
      },
      time: {
        useUTC: false
      },
      series: series
    };

    return (
      <Grid container item
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={1}
        xs={12}>
        <Grid item xs={11}>
          <SignalSelect
            id={id}
            key={'signalSelect' + id}
            label="signals"
            name="signals"
            series={series}
            values={values}
            loading={loading}
            onSelect={this.handleSelect}
            onDeselect={this.handleDeselect}
            options={options}
          />
        </Grid>
        <Grid item xs={1}>
          <DeleteButton onClick={() => onRemove(id)}/>
        </Grid>
        <Grid item xs={12}>
          <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
        </Grid>
      </Grid>
    )
  }
}

export default DebugGraph;
