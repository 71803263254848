import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { GridExport, GridRegion, GridSetting, GridWgra } from './components'
// ========================================

export default function InstallationPage (props) {
  const { readonly = false } = props;

  return (
    <Container maxWidth="lg">
      <Grid container justify="space-between" spacing={1}>
        <GridRegion readonly={readonly} />
        <GridExport readonly={readonly} />
        <GridWgra readonly={readonly} />
        <GridSetting readonly={readonly} />
      </Grid>
    </Container>
  );
}
