import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress } from '@material-ui/core';
import { InstallPaper, InstallBox, InstallSelect, Header, Label } from './parts';
import { SaveButton, RefreshButton } from './parts'
import { getGridRegion, getGridRegionOption, sendGridRegion } from '../../../actions/installation/gridActions'

import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


function RegionSetting({ data, parameter, name, value, unit }) {
 
  if(!data) return null;

  return(
    <InstallBox key={parameter}>
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={9}>
          <Label>{name}</Label>
        </Grid>
        <Grid item xs={3} align="right">
          <Label>{data[parameter]} {unit}</Label>
        </Grid>
      </Grid>
    </InstallBox>
  );
}


export function GridRegion({ readonly }) {
  const dispatch = useDispatch();
  const data = useSelector(state => state.installation.grid.region);
  const options = useSelector(state => state.installation.grid.regionOption);
  const settings = useSelector(state => state.installation.grid.regionSetting);
  const loading = useSelector(state => state.installation.grid.regionLoading);
  const [value, setValue] = useState('');
  const selected = options.find(x => x.value === data);

  useEffect(() => {
    dispatch(getGridRegion());
    dispatch(getGridRegionOption());
  }, [dispatch]);

  function handleSave(e) {
    e.preventDefault();
    dispatch(sendGridRegion(value));
  }

  function handleRefresh() {
    dispatch(getGridRegion());
  }

  function renderData() {
    if (data === null) {
      return (
        <InstallBox>
          <Grid container justify="space-between" alignItems="center" spacing={1}>
            <Grid item xs={8}>
              <Label>Select Region</Label>
            </Grid>
            <Grid item xs={3} align="right">
              <InstallSelect
                value={value}
                label="region"
                name="region"
                options={options}
                onChange={e => setValue(e.target.value)}
                disabled={readonly}
              />
            </Grid>
            <Grid item xs={1} align="right">
              <SaveButton fullWidth label="save" onClick={handleSave} disabled={readonly || value === ''}/>
            </Grid>
          </Grid>
        </InstallBox>
      );
    }

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Accordion disabled={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs={9}>
                  <Typography>Region</Typography>
                </Grid>
                <Grid item xs={3} align="right">
                  <Typography>{selected?selected.label:""}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid xs={12}>              
                <RegionSetting data={settings} parameter="high_frequency_max" name="High Frequency Monitor Max Power" unit="Hz"/>
                <RegionSetting data={settings} parameter="high_frequency_recover" name="High Frequency Monitor Recover" unit="Hz"/>
                <RegionSetting data={settings} parameter="high_frequency_start" name="High Frequency Monitor Start" unit="Hz"/>
                <RegionSetting data={settings} parameter="high_frequency_stop" name="High Frequency Monitor Stop" unit="Hz"/>
                <RegionSetting data={settings} parameter="low_frequency_max" name="Low Frequency Monitor Max Power" unit="Hz"/>
                <RegionSetting data={settings} parameter="low_frequency_recover" name="Low Frequency Monitor Recover" unit="Hz"/>
                <RegionSetting data={settings} parameter="low_frequency_start" name="Low Frequency Monitor Start" unit="Hz"/>
                <RegionSetting data={settings} parameter="low_frequency_stop" name="Low Frequency Monitor Stop" unit="Hz"/>
                <RegionSetting data={settings} parameter="frequency_max" name="Passive Anti Islanding Over-Frequency" unit="Hz"/>
                <RegionSetting data={settings} parameter="ai_voltage_max" name="Passive Anti Islanding Overvoltage 1" unit="V"/>
                <RegionSetting data={settings} parameter="voltage_rms_max" name="Passive Anti Islanding Overvoltage 2" unit="V"/>
                <RegionSetting data={settings} parameter="ai_frequency_min" name="Passive Anti Islanding Under-Frequency" unit="Hz"/>
                <RegionSetting data={settings} parameter="ai_frequency_delay" name="Passive Anti Islanding Under-Frequency Delay" unit="ms"/>
                <RegionSetting data={settings} parameter="ai_voltage_min" name="Passive Anti Islanding Undervoltage 1" unit="V"/>
                <RegionSetting data={settings} parameter="ai_voltage_min2" name="Passive Anti Islanding Undervoltage 2" unit="V"/>
                <RegionSetting data={settings} parameter="voltage_limit" name="Sustained Voltage Limit" unit="V"/>
                <RegionSetting data={settings} parameter="var_ratio_high" name="Volt-Var Set Point High Voltage"/>
                <RegionSetting data={settings} parameter="var_ratio_low" name="Volt-Var Set Point Low Voltage"/>
                <RegionSetting data={settings} parameter="voltvar_v1" name="Volt-Var V1 Reference" unit="V"/>
                <RegionSetting data={settings} parameter="voltvar_v2" name="Volt-Var V2 Reference" unit="V"/>
                <RegionSetting data={settings} parameter="voltvar_v3" name="Volt-Var V3 Reference" unit="V"/>
                <RegionSetting data={settings} parameter="voltvar_v4" name="Volt-Var V4 Reference" unit="V"/>
                <RegionSetting data={settings} parameter="voltwatt_ratio_high" name="Volt-Watt Set Point High Voltage"/>
                <RegionSetting data={settings} parameter="voltwatt_ratio_low" name="Volt-Watt Set Point Low Voltage"/>
                <RegionSetting data={settings} parameter="vrm_v1" name="Volt-Watt V1 Reference" unit="V"/>
                <RegionSetting data={settings} parameter="vrm_v2" name="Volt-Watt V2 Reference" unit="V"/>
                <RegionSetting data={settings} parameter="vrm_v3" name="Volt-Watt V3 Reference" unit="V"/>
                <RegionSetting data={settings} parameter="vrm_v4" name="Volt-Watt V4 Reference" unit="V"/>
              </Grid>            
            </AccordionDetails>
          </Accordion>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <InstallPaper>
      <Header>
        Region
        {loading?<CircularProgress size={16}/>:<RefreshButton onClick={handleRefresh}/>}
      </Header>     
      <Grid container spacing={0} justify="center" alignItems="center">
          <Grid container justify="space-between" alignItems="center" spacing={1}>
            {renderData()}
          </Grid>
      </Grid>
    </InstallPaper>
  );
};
