import API from '../../api';

// Create Redux action types
export const GET_SIGNALS_OPTIONS_REQUEST = 'GET_SIGNALS_OPTIONS_REQUEST';
export const GET_SIGNALS_OPTIONS_SUCCESS = 'GET_SIGNALS_OPTIONS_SUCCESS';
export const GET_SIGNALS_OPTIONS_FAILURE = 'GET_SIGNALS_OPTIONS_FAILURE';

// Create Redux action creators that return an action
export const getSignalsOptions = () => ({
  type: GET_SIGNALS_OPTIONS_REQUEST,
})

export const getSignalsOptionsSuccess = (data) => ({
  type: GET_SIGNALS_OPTIONS_SUCCESS,
  payload: data,
})

export const getSignalsOptionsFailure = () => ({
  type: GET_SIGNALS_OPTIONS_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchSignalsOptions() {
  return async (dispatch) => {
    dispatch(getSignalsOptions())
    try {
      const response = await API.get('/api/debug/signal/options/');
      dispatch(getSignalsOptionsSuccess(response.data));
    } catch (error) {
      dispatch(getSignalsOptionsFailure());
    }
  }
}

// Create Redux action types
export const SIGNALS_ADD_GRAPH = 'SIGNALS_ADD_GRAPH';
export const SIGNALS_REMOVE_GRAPH = 'SIGNALS_REMOVE_GRAPH';
export const SIGNALS_REMOVE_SERIES = 'SIGNALS_REMOVE_SERIES';

// Create Redux action creators that return an action
export const signalsAddGraph = () => ({
  type: SIGNALS_ADD_GRAPH,
})

export const signalsRemoveGraph = (id) => ({
  type: SIGNALS_REMOVE_GRAPH,
  payload: id,
})

export const signalsRemoveSeries = (id, value) => ({
  type: SIGNALS_REMOVE_SERIES,
  payload: { id: id, value: value }
})

// Create Redux action types
export const GET_SIGNALS_LOAD_REQUEST = 'GET_SIGNALS_LOAD_REQUEST';
export const GET_SIGNALS_LOAD_SUCCESS = 'GET_SIGNALS_LOAD_SUCCESS';
export const GET_SIGNALS_LOAD_FAILURE = 'GET_SIGNALS_LOAD_FAILURE';

// Create Redux action creators that return an action
export const getSignalsLoad = () => ({
  type: GET_SIGNALS_LOAD_REQUEST,
})

export const getSignalsLoadSuccess = (id, key, data) => ({
  type: GET_SIGNALS_LOAD_SUCCESS,
  payload: { id: id, key: key, data: data }
})

export const getSignalsLoadFailure = () => ({
  type: GET_SIGNALS_LOAD_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchSignalsLoad(id, key) {
  return async (dispatch) => {
    dispatch(getSignalsLoad())
    try {
      const response = await API.get(`api/debug/signal/data/${key.group}/${key.parameter}/`);
      dispatch(getSignalsLoadSuccess(id, key, response.data));
    } catch (error) {
      dispatch(getSignalsLoadFailure());
    }
  }
}

// Create Redux action types
export const GET_SIGNALS_CLOCKDIVIDER_REQUEST = 'GET_SIGNALS_CLOCKDIVIDER_REQUEST';
export const GET_SIGNALS_CLOCKDIVIDER_SUCCESS = 'GET_SIGNALS_CLOCKDIVIDER_SUCCESS';
export const GET_SIGNALS_CLOCKDIVIDER_FAILURE = 'GET_SIGNALS_CLOCKDIVIDER_FAILURE';

// Create Redux action creators that return an action
export const getSignalsClockDivider = () => ({
  type: GET_SIGNALS_CLOCKDIVIDER_REQUEST,
})

export const getSignalsClockDividerSuccess = (data) => ({
  type: GET_SIGNALS_CLOCKDIVIDER_SUCCESS,
  payload: data,
})

export const getSignalsClockDividerFailure = () => ({
  type: GET_SIGNALS_CLOCKDIVIDER_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchSignalsClockDivider() {
  return async (dispatch) => {
    dispatch(getSignalsClockDivider())
    try {
      const response = await API.get('/api/debug/clockdivider/');
      dispatch(getSignalsClockDividerSuccess(response.data));
    } catch (error) {
      dispatch(getSignalsClockDividerFailure());
    }
  }
}

// Create Redux action types
export const PUT_SIGNALS_CLOCKDIVIDER_REQUEST = 'PUT_SIGNALS_CLOCKDIVIDER_REQUEST';
export const PUT_SIGNALS_CLOCKDIVIDER_SUCCESS = 'PUT_SIGNALS_CLOCKDIVIDER_SUCCESS';
export const PUT_SIGNALS_CLOCKDIVIDER_FAILURE = 'PUT_SIGNALS_CLOCKDIVIDER_FAILURE';

// Create Redux action creators that return an action
export const putSignalsClockDivider = () => ({
  type: PUT_SIGNALS_CLOCKDIVIDER_REQUEST,
})

export const putSignalsClockDividerSuccess = (data) => ({
  type: PUT_SIGNALS_CLOCKDIVIDER_SUCCESS,
  payload: data,
})

export const putSignalsClockDividerFailure = () => ({
  type: PUT_SIGNALS_CLOCKDIVIDER_FAILURE,
})

// Combine them all in an asynchronous thunk
export function sendSignalsClockDivider(value) {
  return async (dispatch) => {
    dispatch(putSignalsClockDivider());
    try {
      const response = await API.put('/api/debug/clockdivider/', {value});
      dispatch(putSignalsClockDividerSuccess(response.data));
      // dispatch(fetchSignalsClockDivider());
    } catch (error) {
      dispatch(putSignalsClockDividerFailure());
    }
  }
}

// Create Redux action types
export const GET_SIGNALS_STOPONFAULT_REQUEST = 'GET_SIGNALS_STOPONFAULT_REQUEST';
export const GET_SIGNALS_STOPONFAULT_SUCCESS = 'GET_SIGNALS_STOPONFAULT_SUCCESS';
export const GET_SIGNALS_STOPONFAULT_FAILURE = 'GET_SIGNALS_STOPONFAULT_FAILURE';

// Create Redux action creators that return an action
export const getSignalsStopOnFault = () => ({
  type: GET_SIGNALS_STOPONFAULT_REQUEST,
})

export const getSignalsStopOnFaultSuccess = (data) => ({
  type: GET_SIGNALS_STOPONFAULT_SUCCESS,
  payload: data,
})

export const getSignalsStopOnFaultFailure = () => ({
  type: GET_SIGNALS_STOPONFAULT_FAILURE,
})

// Combine them all in an asynchronous thunk
export function fetchSignalsStopOnFault() {
  return async (dispatch) => {
    dispatch(getSignalsStopOnFault())
    try {
      const response = await API.get('/api/debug/stoponfault/');
      dispatch(getSignalsStopOnFaultSuccess(response.data));
    } catch (error) {
      dispatch(getSignalsStopOnFaultFailure());
    }
  }
}

// Create Redux action types
export const PUT_SIGNALS_STOPONFAULT_REQUEST = 'PUT_SIGNALS_STOPONFAULT_REQUEST';
export const PUT_SIGNALS_STOPONFAULT_SUCCESS = 'PUT_SIGNALS_STOPONFAULT_SUCCESS';
export const PUT_SIGNALS_STOPONFAULT_FAILURE = 'PUT_SIGNALS_STOPONFAULT_FAILURE';

// Create Redux action creators that return an action
export const putSignalsStopOnFault = () => ({
  type: PUT_SIGNALS_STOPONFAULT_REQUEST,
})

export const putSignalsStopOnFaultSuccess = (data) => ({
  type: PUT_SIGNALS_STOPONFAULT_SUCCESS,
  payload: data,
})

export const putSignalsStopOnFaultFailure = () => ({
  type: PUT_SIGNALS_STOPONFAULT_FAILURE,
})

// Combine them all in an asynchronous thunk
export function sendSignalsStopOnFault(value) {
  return async (dispatch) => {
    dispatch(putSignalsStopOnFault());
    try {
      const response = await API.put('/api/debug/stoponfault/', {value});
      dispatch(putSignalsStopOnFaultSuccess(response.data));
      // dispatch(fetchSignalsStopOnFault());
    } catch (error) {
      dispatch(putSignalsStopOnFaultFailure());
    }
  }
}

// Create Redux action types
export const PUT_SIGNALS_CLEAR_REQUEST = 'PUT_SIGNALS_CLEAR_REQUEST';
export const PUT_SIGNALS_CLEAR_SUCCESS = 'PUT_SIGNALS_CLEAR_SUCCESS';
export const PUT_SIGNALS_CLEAR_FAILURE = 'PUT_SIGNALS_CLEAR_FAILURE';

// Create Redux action creators that return an action
export const putSignalsClear = () => ({
  type: PUT_SIGNALS_CLEAR_REQUEST,
})

export const putSignalsClearSuccess = (data) => ({
  type: PUT_SIGNALS_CLEAR_SUCCESS,
  payload: data,
})

export const putSignalsClearFailure = () => ({
  type: PUT_SIGNALS_CLEAR_FAILURE,
})

// Combine them all in an asynchronous thunk
export function sendSignalsClear() {
  return async (dispatch) => {
    dispatch(putSignalsClear());
    try {
      const response = await API.get('/api/debug/signal/clear/');
      dispatch(putSignalsClearSuccess(response.data));
    } catch (error) {
      dispatch(putSignalsClearFailure());
    }
  }
}

