import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { fetchBmsValue,
         fetchBmsInformation,
         fetchBmsChipConfig,
         sendBmsCommand,
         clearBmsCommandError,
         fetchBmsStatus } from '../../actions/debug/bmsActions'

import ButtonList from '../../components/List/ButtonList';
import BmsValueList from '../../components/List/BmsValueList';
import KeyValueList from '../../components/List/KeyValueList';
import StatusArrayList from '../../components/List/StatusArrayList';

// ========================================

export function DebugBmsPage () {
  const dispatch = useDispatch()
  const data = useSelector(state => state.debug.bms)

  useEffect(() => {
    dispatch(fetchBmsValue());
    const intervalValue = setInterval(() => {
      dispatch(fetchBmsValue());
    }, 15000);
    return () => clearInterval(intervalValue);
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBmsStatus());
    const intervalStatus = setInterval(() => {
      dispatch(fetchBmsStatus());
    }, 30000);
    return () => clearInterval(intervalStatus);
  }, [dispatch]);

  function sendCommand(data) {
    dispatch(sendBmsCommand(data));
  }

  useEffect(() => {
    dispatch(fetchBmsInformation());
    dispatch(fetchBmsChipConfig());
  }, [dispatch]);

  return (
    <Container maxWidth="xl">
      <Grid container item justify="center" spacing={2} xs={12} sm={12} md={12}>
        <Grid item xs={12} sm={12} md={2}>
          <KeyValueList
            data= {data.information}
            title="Information"
            loading={data.informationLoading}
            refresh={() => dispatch(fetchBmsInformation())}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <BmsValueList
            data={data.value}
            title="Values"
            loading={data.valueLoading}
            refresh={() => dispatch(fetchBmsValue())}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <ButtonList
            loading={data.commandLoading}
            error={data.commandError}
            command={sendCommand}
            clear={() => dispatch(clearBmsCommandError())}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <KeyValueList
            data={data.chipConfig}
            title="Chip Config"
            loading={data.chipConfigLoading}
            refresh={() => dispatch(fetchBmsChipConfig())}
          />
        </Grid>          
        <Grid item xs={12} sm={12} md={3}>
          <StatusArrayList
            data={data.status}
            loading={data.statusLoading}
            refresh={() => dispatch(fetchBmsStatus())}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
