import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress } from '@material-ui/core';
import { TableContainer, TableRow, TableHead, TableBody } from '@material-ui/core';
import { CommandButton } from '../Button/Button';
import { DebugTable, DebugTableCell } from '../Table/DebugTable';
import { DebugPaper } from '../Paper/Paper';
import { fetchDebugState, sendDebugStateClear } from '../../actions/debug/stateActions'

// ========================================

export default function StateMachine () {
  const dispatch = useDispatch()
  const data = useSelector(state => state.debug.state.data)
  const loading = useSelector(state => state.debug.state.loading)

  useEffect(() => {
    dispatch(fetchDebugState());
  }, [dispatch]);

  if(!data.length) return null;

  const columns = Object.keys(data[0])
  columns.splice(columns.indexOf('id'), 1);

  return (
    <DebugPaper>
      <Grid container item
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={1}
        xs={12}>
        <Grid item>
          Debug State Machines
        </Grid>
        <Grid item>
          {loading?<CircularProgress size={16} />:null}
        </Grid>
      </Grid>
      <Grid container item
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={1}
        xs={12}>
        <Grid item xs={8}>
          <Grid container item
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
            xs={12}>
            <Grid item>
              <CommandButton 
                onClick={() => dispatch(sendDebugStateClear())}
                disabled={loading}>
                Clear
              </CommandButton>
            </Grid>
            <Grid item>
              <CommandButton 
                onClick={() => dispatch(fetchDebugState())}
                disabled={loading}>
                Refresh
              </CommandButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={1}
        xs={12}
      >
        <Grid item xs={12}>
          <TableContainer component={DebugPaper}>
            <DebugTable>
              <TableHead>
                <TableRow>
                  {columns.map(item =>
                    <DebugTableCell key={item}>
                      {item}
                    </DebugTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(item =>
                  <TableRow key={item.id}>
                    {columns.map(col =>
                      <DebugTableCell key={item.id+col}>
                        {item[col]}
                      </DebugTableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </DebugTable>
          </TableContainer>
        </Grid>
      </Grid>
    </DebugPaper>
  );

}
