// Import all actions
import * as actions from '../actions/operationActions'

import { operationPicture } from '../utils/enums'


export const initialState = {
  operation: undefined,
  operationPicture: operationPicture,
  clearing: false,
  updating: false,
  loading: false,
  dataLoading: false,
  error: false,
}

export default function operationPictureReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_OPERATION_REQUEST:
      return { ...state, loading: true }
    case actions.GET_OPERATION_SUCCESS:
      return { ...state, operation: action.payload, loading: false, error: false }
    case actions.GET_OPERATION_FAILURE:
      return { ...state, loading: false, error: true }
    case actions.PUT_OPERATION_REQUEST:
      return { ...state, updating: true }
    case actions.PUT_OPERATION_SUCCESS:
      return { ...state, operation: action.payload, updating: false, error: false }
    case actions.PUT_OPERATION_FAILURE:
      return { ...state, updating: false, error: true }
    case actions.GET_OPERATION_PICTURE_REQUEST:
      return { ...state, dataLoading: true }
    case actions.GET_OPERATION_PICTURE_SUCCESS:
      return { ...state, operationPicture: action.payload, dataLoading: false, error: false }
    case actions.GET_OPERATION_PICTURE_FAILURE:
      return { ...state, operationPicture: operationPicture, dataLoading: false, error: true }
    case actions.PUT_CLEAR_HALT_REQUEST:
      return { ...state, clearing: true }
    case actions.PUT_CLEAR_HALT_SUCCESS:
      return { ...state, clearing: false, error: false }
    case actions.PUT_CLEAR_HALT_FAILURE:
      return { ...state, clearing: false, error: true }
    default:
      return state
  }
}


