export const operationOptions = {
  mode: [
    { value: 'auto', label: 'Automatic' },
    { value: 'manual', label: 'Manual' },
  ],
  system: [
    { value: 'interactive', label: 'Grid Interactive' },
    { value: 'debug', label: 'Debug' },
    { value: 'off', label: 'Off' }
  ],
  battery: [
    { value: 'off', label: 'Off' },
    { value: 'charge', label: 'Charge' },
    { value: 'discharge', label: 'Discharge' },
    { value: 'excess_only', label: 'Minimise Export' },
    { value: 'offset_import', label: 'Minimise Import' },
    { value: 'self_usage', label: 'Minimise Usage' }
  ],
  limit: [
    { value: '1000', label: '1kW' },
    { value: '2000', label: '2kW' },
    { value: '3000', label: '3kW' },
    { value: '4000', label: '4kW' },
    { value: '5000', label: '5kW' }
  ],
  reserve: [
    { value: '0', label: 'Disabled' },
    { value: '5', label: '5%' },
    { value: '25', label: '25%' },
    { value: '50', label: '50%' },
    { value: '100', label: '100%' }
  ]
}