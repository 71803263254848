import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";

const OpTypography = withStyles({
  root: {
    fontSize: '13px',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    letterSpacing: '0.8px',
    width: '100%',
  },
})(Typography);

const OpMenuItem = withStyles({
  root: {
    fontSize: "13px",
    color: "#102d39",
  },
  selected: {
    color: "#fff",
    backgroundColor: "#102d39",
    "&:focus": {
      color: "#fff",
      backgroundColor: "#102d39",
    },
    "&:hover": {
      color: "#fff",
      backgroundColor: "#102d39",
    },
  },
})(MenuItem);

const OpInput = withStyles({
  root: {
    width: "100%",
  },
  input: {
    borderRadius: 3,
    backgroundColor: "#102d39",
    border: "1px solid #102d39",
    fontSize: "13px",
    padding: "10px 26px 10px 12px",
    "&:focus": {
      backgroundColor: "#102d39",
      borderRadius: "3px",
      borderColor: "#102d39"
    }
  }
})(InputBase);

const OpSelect = withStyles({
  root: {
    color: "#fff",
  },
  icon: {
    color: "#fab529",
    fontSize: "38px",
    marginTop: "-7px",
  },
  selectMenu: {
    width: "100%",
  },
})(Select);

// ========================================

class OperationSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      label,
      name,
      value,
      onChange,
      options,
      disabled,
    } = this.props;

    return (
      <React.Fragment>
        <Grid item xs={4}>
          <OpTypography>{label}</OpTypography>
        </Grid>
        <Grid item xs={8}>
          <OpSelect
            id={label}
            name={name}
            onChange={onChange}
            value={value}
            disabled={disabled}
            input={<OpInput />}
          >
            {options.map((item, index) =>
              <OpMenuItem key={label+index} value={item.value}>{item.label}</OpMenuItem>
            )}
          </OpSelect>
        </Grid>
      </React.Fragment>
    );
  }
}

// ========================================

export default OperationSelect;
