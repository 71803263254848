// Import all actions
import * as actions from '../actions/logsActions'

export const initialState = {
  data: [],
  loading: false,
  errors: false,
}

export default function logsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_LOGS_REQUEST:
      return { ...state, loading: true }
    case actions.GET_LOGS_SUCCESS:
      return { ...state, data: action.payload, loading: false, errors: false }
    case actions.GET_LOGS_FAILURE:
      return { ...state, loading: false, erors: true }
    default:
      return state
  }
}


