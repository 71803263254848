import React from 'react'
import { NavLink } from 'react-router-dom'
import './TopMenu.css';

const Logout = () => <NavLink
    className="TopMenuItem TopMenuLogout"
    to="/logout"
    >Logout
  </NavLink>

const Login = () => <NavLink
    className="TopMenuItem TopMenuLogout"
    to="/login"
    >Login
  </NavLink>

const TopMenu = ({ links, loggedIn }) => {
  return (
    <div className="TopMenu">
      {links.map(item =>
        <NavLink key={item}
          className="TopMenuItem"
          to={"/"+item}
          >{item}
        </NavLink>
      )}
      {loggedIn ? <Logout /> : <Login />}
    </div>
  );
}

export default TopMenu;