import React from 'react';
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router' 
import { Login, Logout } from './container';

import SplashScreen from './components/SplashScreen/SplashScreen';
import InstallationPage from './pages/installation/InstallationPage'
import OperationPage from './pages/OperationPage';
import NetworkPage from './pages/network/NetworkPage'
import LogsPage from './pages/logs/LogsPage'
import UpgradePage from './pages/upgrade/UpgradePage'

import {
  DebugStatusPage,
  DebugBmsPage,
  DebugSignalsPage,
  DebugStatePage
} from './pages/debug'

export default function Routing() {
  const errorCount = useSelector(state => state.event.errorCount);

  if (errorCount > 2) {
    return (
      <SplashScreen />
    );
  }

  return (
    <Switch>
      <Route exact path="/operation" component={OperationPage} />
      <Route exact path="/logs" component={LogsPage} />
      <Route exact path="/network" component={NetworkPage} />
      <Route exact path="/upgrade" component={UpgradePage} />
      <Route exact path="/installation" component={InstallationPage} />
      <Route exact path="/debug/status" component={DebugStatusPage} />
      <Route exact path="/debug/bms" component={DebugBmsPage} />
      <Route exact path="/debug/signals" component={DebugSignalsPage} />
      <Route exact path="/debug/state" component={DebugStatePage} />
      <Redirect exact from="/debug" to="/debug/status" />
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <Redirect exact from="/" to="/operation" />
    </Switch>
  );
}
