import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Container, Grid } from '@material-ui/core';
import { XGrid, getGridNumericColumnOperators, getGridStringOperators } from '@material-ui/x-grid';
import { getLogs } from '../../actions/logsActions'
import { RefreshButton, ReportingPaper } from './components/parts';
// ===========================================================================

const severityType = {
  0: 'Emergency',
  1: 'Alert',
  2: 'Critical',
  3: 'Error',
  4: 'Warning',
  5: 'Notice',
  6: 'Information',
  7: 'Debug',
};

function DateStrToDate(params) {
  let d = new Date(params.value+'Z');
  return (
    ("0" + d.getDate()).slice(-2) + "/" +      
    ("0" + (d.getMonth()+1)).slice(-2) + "/" +
    d.getFullYear() + " " +
    ("0" + d.getHours()).slice(-2) + ":" +
    ("0" + d.getMinutes()).slice(-2) + ":" +
    ("0" + d.getSeconds()).slice(-2) + "." +
    ("00" + d.getMilliseconds()).slice(-3)
  );
}

function severityToStr(params) {
  return severityType[params.value]
}

const severityColumnType = {
  extendType: 'number',
  filterOperators: getGridNumericColumnOperators()
    .filter((operator) => operator.value === '=' || operator.value === '>=' || operator.value === '<=')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

const sourceColumnType = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

const messageColumnType = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'contains')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

const columnTypes = {
  severity: severityColumnType,
  source: sourceColumnType,
  message: messageColumnType,
}


export default function LogsPage () {
  const dispatch = useDispatch();
  const data = useSelector(state => state.logs.data);
  const loading = useSelector(state => state.logs.loading);
  const error = useSelector(state => state.logs.error);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    dispatch(getLogs());
  }, [dispatch]);

  const columns: GridColDef[] = [
    { 
      field: 'Timestamp',
      headerName: 'Timestamp',
      valueFormatter: DateStrToDate,
      type: 'dateTime',
      resizable:false,
      width: 190
    },
    {
      field: 'Severity',
      headerName: 'Severity',
      valueFormatter: severityToStr,
      type: 'severity',
      resizable:false,
      width: 130
    },
    { field: 'Source', headerName: 'Source', type: 'source', sortable: false, width: 150 },
    { field: 'Message', headerName: 'Message', type: 'message', sortable: false, flex: 1 },
  ];

  function refreshData() {
    dispatch(getLogs());
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <ReportingPaper>
          <Grid container item
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
            xs={12}>
            <Grid item>
              Logs
            </Grid>
            <Grid item>
              {loading ? null:<RefreshButton onClick={refreshData}/>}
            </Grid>
          </Grid>
          <Grid container item
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={1}
            xs={12}>
            <Grid item xs={12}>
              <div style={{ display: 'flex' }}>
                <XGrid
                  autoHeight
                  pagination
                  rows={data}
                  columns={columns}
                  loading={loading}
                  error={error}                
                  pageSize={pageSize}
                  rowCount={data?.length}
                  disableSelectionOnClick
                  onPageSizeChange={(params) => setPageSize(params.pageSize)}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  rowHeight={28}
                  headerHeight={35}
                  columnTypes={columnTypes}
                />
              </div>
            </Grid>
          </Grid>
        </ReportingPaper>
      </Container>
    </React.Fragment>
  );
}
