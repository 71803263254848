import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';


export const CommandButton = withStyles({
  root: {
    height: '40px',
    width: '100%',
    fontSize: '14px',
    color: 'inherit',
    border: "1px solid #004057",
    // marginBottom: '10px',
    '&:hover': {
      color: '#fff',
      background: '#004057',
      opacity: '1',
    },
    '&:disabled': {
    },
  },
})(props => <Button disableRipple disableElevation variant="outlined" {...props}/>);
           
export const HeaderButton = withStyles({
  root: {
    display: 'inline',
    height: '30px',
    // minWidth: '100px',
    fontSize: '13px',
    color: 'inherit',
    border: "1px solid #004057",
    // marginBottom: '10px',
    '&:hover': {
      color: '#fff',
      background: '#004057',
      opacity: '1',
    },
    '&:disabled': {
    },
  },
})(props => <Button disableRipple disableElevation variant="outlined" {...props}/>);           