import React from 'react';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Cached, Delete } from '@material-ui/icons';

const IconButtonNoHover = withStyles({
  root: {
    color: 'inherit',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
})(IconButton);

export function RefreshButton(props) {
  return(
    <IconButtonNoHover
      size="small"
      aria-label="refresh"
      onClick={() => props.onClick()}
    >
      <Cached />
    </IconButtonNoHover>
  );
}

export function DeleteButton(props) {
  return(
    <IconButtonNoHover
      size="small"
      aria-label="refresh"
      onClick={() => props.onClick()}
    >
      <Delete />
    </IconButtonNoHover>
  );
}
