import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Brightness1 from '@material-ui/icons/Brightness1';

export const RedDot = withStyles({
  root: {
    color: 'red',
  },
})(props => (<Brightness1 fontSize="inherit" {...props} />));

export const GreenDot = withStyles({
  root: {
    color: 'green',
  },
})(props => (<Brightness1 fontSize="inherit" {...props} />));
