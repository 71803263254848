import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { DebugTable, DebugTableCell } from '../Table/DebugTable';
import { DebugPaper } from '../Paper/Paper';
import { Panel,  PanelSummary, PanelDetails } from '../Panel/ExpansionPanel';
import { RefreshButton } from '../Button/IconButton';

const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

// ========================================

class ChipList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      group,
      data
    } = this.props;

    if (!data) return null;

    return (
      <Panel defaultExpanded={true}>
        <PanelSummary
          expandIcon={<ArrowDropDown />}
          aria-controls={group + 'content'}
          id={group}
        >{group} 
        </PanelSummary>
        <PanelDetails>
          <TableContainer component={DebugPaper}>
            <DebugTable>
              <colgroup>
                <col />
                <col />
              </colgroup>
              <TableBody>
              {data.cell_volts?data.cell_volts.map((value, idx) =>
                <TableRow key={value+'volt'+idx}>
                  <DebugTableCell  component="th" scope="row">
                    Cell {idx}
                  </DebugTableCell>
                  <DebugTableCell align="right">
                    {value} mV
                  </DebugTableCell>
                </TableRow>
              ):null}
              {data.cell_temps?data.cell_temps.map((value, idx) =>
                <TableRow key={value+'temp'+idx}>
                  <DebugTableCell  component="th" scope="row">
                    Sensor {idx}
                  </DebugTableCell>
                  <DebugTableCell align="right">
                    {value/10} °C
                  </DebugTableCell>
                </TableRow>
              ):null}
              {data.analog_die_temps?
                <TableRow>
                  <DebugTableCell  component="th" scope="row">
                    Analog
                  </DebugTableCell>
                  <DebugTableCell align="right">
                    {data.analog_die_temps/10} °C
                  </DebugTableCell>
                </TableRow>
              :null}
              {data.digital_die_temps?
                <TableRow>
                  <DebugTableCell  component="th" scope="row">
                    Digital
                  </DebugTableCell>
                  <DebugTableCell align="right">
                    {data.digital_die_temps/10} °C
                  </DebugTableCell>
                </TableRow>
              :null}
              </TableBody>
            </DebugTable>
          </TableContainer>
        </PanelDetails>
      </Panel>
    );
  }
}

// ========================================

class SystemList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      group,
      data
    } = this.props;

    if (!data) return null;

    const keys = Object.keys(data);

    return (
      <Panel defaultExpanded={true}>
        <PanelSummary
          expandIcon={<ArrowDropDown />}
          aria-controls={group + 'content'}
          id={group}
        >{group} 
        </PanelSummary>
        <PanelDetails>
          <TableContainer component={DebugPaper}>
            <DebugTable>
              <colgroup>
                <col />
                <col />
              </colgroup>
              <TableBody>
                {keys.map(key =>
                  <TableRow key={key}>
                    <DebugTableCell  component="th" scope="row">
                      {titleCase(key)}
                    </DebugTableCell>
                    <DebugTableCell align="right">
                      {Math.round(data[key]*100)/100}
                    </DebugTableCell>
                  </TableRow>
                )}            
              </TableBody>
            </DebugTable>
          </TableContainer>
        </PanelDetails>
      </Panel>
    );
  }
}

// ========================================

class BmsValueList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      data,
      title,
      refresh,
      loading
    } = this.props;

    return (
      <DebugPaper>
        <Grid container item
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
          xs={12}>
          <Grid item>
            {title}
          </Grid>
          <Grid item>
            {loading ? <CircularProgress size={16} />:<RefreshButton onClick={refresh}/>}
          </Grid>
        </Grid>

          <SystemList
            group='system'
            key='system'
            data={data.system}
          />

          <ChipList
            group='chip0'
            key='chip0'
            data={data.chip0}
          />
          <ChipList
            group='chip1'
            key='chip1'
            data={data.chip1}
          />
          <ChipList
            group='chip2'
            key='chip2'
            data={data.chip2}
          />
      </DebugPaper>
    );
  }
}

// ========================================

export default BmsValueList;