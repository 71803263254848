// Import all actions
import * as actions from '../actions/networkActions'

export const initialState = {
  setting: {},
  signal: {},
  status: {},
  apList: [],
  loading: false,
  apListLoading: false,
  error: false,
  apListError: false
}

export default function networkReducer(state = initialState, action) {
  switch (action.type) {

    case actions.GET_NETWORK_SETTING_REQUEST:
    case actions.PUT_NETWORK_SETTING_REQUEST:
      return { ...state, loading: true }
    case actions.GET_NETWORK_SETTING_SUCCESS:
    case actions.PUT_NETWORK_SETTING_SUCCESS:
      return { ...state, setting: action.payload, loading: false, error: false }
    case actions.GET_NETWORK_SETTING_FAILURE:
    case actions.PUT_NETWORK_SETTING_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case actions.PUT_NETWORK_SETTING_CLEAR_ERROR:
      return { ...state, error: false }

    case actions.GET_NETWORK_SIGNAL_REQUEST:
      return { ...state, loading: true }
    case actions.GET_NETWORK_SIGNAL_SUCCESS:
      return { ...state, signal: action.payload, loading: false, error: false }
    case actions.GET_NETWORK_SIGNAL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case actions.GET_NETWORK_STATUS_REQUEST:
      return { ...state, loading: true }
    case actions.GET_NETWORK_STATUS_SUCCESS:
      return { ...state, status: action.payload, loading: false, error: false }
    case actions.GET_NETWORK_STATUS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case actions.GET_NETWORK_APLIST_REQUEST:
      return { ...state, apListLoading: true }
    case actions.GET_NETWORK_APLIST_SUCCESS:
      return { ...state, apList: action.payload, apListLoading: false, apListError: false }
    case actions.GET_NETWORK_APLIST_FAILURE:
      return { ...state, apListLoading: false, apListError: action.payload }

    default:
      return state
  }
}


