import React from 'react';
import Grid from '@material-ui/core/Grid';
import { DebugPaper } from '../Paper/Paper';
import { CommandButton } from '../Button/Button';
import SignalGraph from './SignalGraph';
import DebugMenu from './Menu'

// ========================================
function sleep(milliseconds) {
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds){
      break;
    }
  }
}
// ========================================

class Signals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.onRefresh = this.onRefresh.bind(this);
  }

  onRefresh() {
    this.props.graphs.map(graph => {
      if (graph.series.length > 0) {
        graph.values.map(value => {
          // let tmpArray = series.key.split("-");
          this.props.addSeries(graph.id, value);
          sleep(700);
          return null;
        });
      }
      return null;
    });
  }

  render() {
    const {
      adcClock,
      clockDivider,
      options,
      loading,
      graphs,
      addGraph,
      removeGraph,
      addSeries,
      removeSeries
    } = this.props;

    return (
      <DebugPaper>
        <DebugMenu title="Debug Signals" refresh={this.onRefresh} loading={loading}/>
        <Grid container item
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
          xs={12}>
          <hr />
          {graphs.sort((a, b) => parseFloat(a.id)-parseFloat(b.id)).map(item =>
            <Grid item key={item.id} xs={12}>
              <SignalGraph
                id={item.id}
                series={item.series}
                values={item.values}
                clockDivider={clockDivider}
                adcClock={adcClock}
                onRemove={removeGraph}
                options={options}
                loading={loading}
                addSeries={addSeries}
                removeSeries={removeSeries}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <CommandButton
              onClick={addGraph}>
              + Add Graph
            </CommandButton>          
          </Grid>
        </Grid>
      </DebugPaper>
    );
  }
}

// ========================================

export default Signals;
