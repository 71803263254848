import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


export const OperationTitle = withStyles({
  root: {
    display: 'inline-flex',
    fontSize: '24px',
    fontWeight: '300%',
    textTransform: 'capitalize',
    letterSpacing: '1.1px',
    width: '100%',
    margin: "12px 0px 5px 0px",
  },
})(Typography);
