import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isNil } from 'lodash';

const getLinePoints = (type, mQuery, lQuery) => {
  let points = {
    x1: 0,
    y1: 10,
    x2: mQuery ? 34 : lQuery ? 60 : 50,
    y2: 10
  };

  switch (type) {
    case 'left': return {
      x1: 15,
      y1: 10,
      x2: lQuery ? 88 : 60,
      y2: 10
    };
    case 'top': return {
      x1: 10,
      y1: 15,
      x2: 10,
      y2: 70
    };

    case 'bottom': return {
      x1: 10,
      y1: 0,
      x2: 10,
      y2: 50
    };

    default: return points;
  }
}

const getStyle = (type, lQuery) => {
  let style = {
    position: 'absolute'
  }

  switch(type) {
    case 'left': return {
        ...style,
        right: lQuery ? '-90px' : '-70px',
        top: '40%'
      }
    case 'right': return {
      ...style,
      right: lQuery ? '-90px' : '-67px',
      top: '40%'
    }
    case 'top': return {
      ...style,
      left: '30%',
      top: '-75px'
    }
    case 'bottom': return {
      ...style,
      left: '30%',
      top: '-70px'
    }
    default: return style;
  }
}

const getHeight = type => {
  switch (type) {
    case 'top': return '80px';
    case 'bottom': return '65px';
    default: return '100px';
  }
}

const getAnimationPoints = type => {
  switch(type) {
    case 'right': return {
      attributeName: 'cx', 
      from: '0',
      to: '65'
    }
    case 'left': return {
      attributeName: 'cx',
      from: '65',
      to: '0'
    }
    case 'top': return {
      attributeName: 'cy',
      from: '65',
      to: '0'
    }
    case 'bottom': return {
      attributeName: 'cy',
      from: '0',
      to: '65'
    }
    default: return {
      attributeName: 'cx',
      from: '0',
      to: '65'
    }
  }
}

const Line = ({ width, height, type }) => {
  const mQuery = useMediaQuery('(min-width:960px) and (max-width:1175px)');
  const sQuery = useMediaQuery('(min-width:600px) and (max-width:800px)');
  const lQuery = useMediaQuery('(min-width:1365px)');
  let linePoints = getLinePoints(type, mQuery || sQuery, lQuery);
  if (isNil(type)) return null;

  return (
  <svg height={getHeight(type)} width={lQuery ? '85px' : width} style={getStyle(type, lQuery)}>
    <defs>
    <marker id="startarrow" markerWidth="8" markerHeight="10" 
      refX="8" refY="5" orient="auto">
      <polygon points="8 0, 8 10, 0 5" fill="rgb(249, 181, 40)" />
    </marker>
      <marker id="endarrow" markerWidth="8" markerHeight="10" 
      refX="0" refY="5" orient="auto">
        <polygon points="0 0, 8 5, 0 10" fill="rgb(249, 181, 40)" />
      </marker>
      <filter id="shadow">
        <feDropShadow dx="0" dy="0" stdDeviation="3"
            flood-color="rgb(249, 181, 40)"/>
      </filter>
    </defs>
    <line 
      x1={linePoints.x1} 
      y1={linePoints.y1} 
      x2={linePoints.x2} 
      y2={linePoints.y2} 
      strokeWidth="2" 
      stroke="rgb(249, 181, 40)" 
      strokeDasharray="5,6" 
      strokeLinecap="round" 
      markerStart={(type === 'top' || type === 'left') && "url(#startarrow)"} 
      markerEnd={(type === 'bottom' || type === 'right') && "url(#endarrow)"} />
    <circle r="8" cx={(type === 'top' || type === 'bottom') ? '10' : '0'} cy="10" fill="rgb(249, 181, 40)" filter="url(#shadow)">
      <animate 
        dur="1s"
        begin="0s"
        repeatCount="indefinite" 
        {...getAnimationPoints(type)}
      />
    </circle>
  </svg>
)};

export default Line;

Line.defaultProps = {
  height: '65px',
  width: '65px'
};
