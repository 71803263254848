import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@material-ui/core/styles';
import { Grid, CircularProgress, Button, TextField } from '@material-ui/core';
import { StatusSwitch } from '../Switch/Switch';
import { CommandButton } from '../Button/Button';
import { SignalLabel } from '../Typography/Label'
import { fetchSignalsClockDivider,
         sendSignalsClockDivider,
         fetchSignalsStopOnFault,
         sendSignalsStopOnFault,
         sendSignalsClear } from '../../actions/debug/signalsActions'


const ValueTextField = styled(TextField)({
  margin: '5px 0px',
  padding: '2px 0px',
  height: '30px',
  width: '100px',
  fontSize: '12px',
  '& input': {
    color: '004057',
    background: '#fff',
    fontSize: '14px',
    padding: '5px',
    height: '20px',
  },
});

const UpdateButton = styled(Button)({
  margin: '0px',
  width: '80px',
  height: '30px',
  color: 'inherit',
  border: "1px solid #004057",
  fontSize: '12px',
  '&:hover': {
    color: '#fff',
    background: '#004057',
  },
  '&:disabled': {
  },
});


// ========================================

export default function DebugMenu ({ title, refresh, loading }) {
  const dispatch = useDispatch()
  const clockDivider = useSelector(state => state.debug.signals.clockDivider)
  const stopOnFault = useSelector(state => state.debug.signals.stopOnFault)
  const [clockInput, setClockInput] = useState(undefined);

  useEffect(()=>{
    setClockInput(clockDivider);
  }, [clockDivider]);

  useEffect(() => {
    dispatch(fetchSignalsStopOnFault());
    dispatch(fetchSignalsClockDivider());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Grid container item
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={1}
        xs={12}>
        <Grid item>
          {title}
        </Grid>
        <Grid item>
          {loading?<CircularProgress size={16} />:null}
        </Grid>
      </Grid>
      <Grid container item
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={1}
        xs={12}>
        <Grid item xs={4}>
          <Grid container item
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
            xs={12}>
            <Grid item>
              <CommandButton 
                onClick={() => dispatch(sendSignalsClear())}
                disabled={loading}>
                Clear
              </CommandButton>
            </Grid>
            <Grid item>
              <CommandButton 
                onClick={refresh}
                disabled={loading}>
                Refresh
              </CommandButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container item
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
            xs={12}>
            <Grid item>
              <SignalLabel>Clock Divider</SignalLabel>
            </Grid>
            <Grid item>
              <ValueTextField
                variant="outlined"
                value={clockInput}
                onChange={(e) => setClockInput(e.target.value)}
              />
            </Grid>
            <Grid item>
              <UpdateButton 
                disabled={loading} 
                onClick={() => dispatch(sendSignalsClockDivider(clockInput))}>
                Update
              </UpdateButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <SignalLabel>Stop on Fault</SignalLabel>
          <StatusSwitch
            disableRipple
            name="stopfault"
            checked={stopOnFault}
            onChange={(e) => dispatch(sendSignalsStopOnFault(e.target.checked))}
            disabled={loading}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );

}
