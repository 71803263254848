import React from 'react';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import { LicenseInfo } from '@material-ui/x-grid';
import { CssBaseline, Container } from '@material-ui/core';
import configureStore from './store'
import TopBar from './components/Menu/TopBar'
import Routing from './Routing';
import Footer from './components/Footer/Footer';

const {history, persistor, store} = configureStore();

LicenseInfo.setLicenseKey(
  'fbee24dbffc2afa7a0f31b65aaf48b2bT1JERVI6MjUyMDYsRVhQSVJZPTE2NTM0MzIyODcwMDAsS0VZVkVSU0lPTj0x',
);

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Container maxWidth="xl" disableGutters>
            <CssBaseline />
            <TopBar/>
            <div style={{ minHeight: 'calc(100vh - 123px - 50px)' }}><Routing /></div>
            <Footer />
          </Container>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}
